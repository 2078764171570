import React, { useState, useEffect, useRef } from "react";
import { DocumentDownloader } from "./DocumentDownloader";
import { NoDocument } from "./NoDocument";
import * as PresenterState from "./PresenterState";
import { useTranslate } from "react-translate";
import './Presenter.css'
import { ErrorDisplayer } from "../../Errors/ErrorDisplayer";

const presentersChangedCache = [];
const presenterPageCache = [];

export function PresenterActivity(props) {
    const t = useTranslate("Presenter");
    const { activityArgs, userId, titleRef, setFooterClassName, addFooterClassName, ...otherProps } = props;
    const [presenterActivityArgs, setPresenterActivityArgs] = useState(undefined);
    const [presenterState, setPresenterState] = useState(PresenterState.waiting);
    const [error, setError] = useState(null);
    const presenterPageCallbackRef = useRef(null);
    const presentersChangedCallbackRef = useRef(null);


    const handlePresenterPageCallback = (callbackFunc) => {
        presenterPageCallbackRef.current = callbackFunc;

        if (callbackFunc) {
            if (presenterPageCache.length > 0) {
                //console.log("verifying " + presenterPageCache.length + " PresenterPage cached events");
                while (presenterPageCache.length > 0) {
                    var element = presenterPageCache.shift();
                    //console.log("flushing event " + element.name);
                    callbackFunc(element.name, element.data, element.time);
                }
            }
        }
    }

    const handlePresentersChangedCallback = (callbackFunc) => {
        presentersChangedCallbackRef.current = callbackFunc;
        if (callbackFunc) {
            if (presentersChangedCache.length > 0) {
                //console.log("verifying " + presentersChangedCache.length + " PresentersChanged cached events");
                // Take the last presentersChangedCached element.
                var element = presentersChangedCache.pop();
                //console.log("flushing event " + element.name);
                callbackFunc(element.name, element.data, element.time);
            }
        }
    }

    function clearTitleClassName(){
        if (titleRef && titleRef.current) {
            titleRef.current.classList = "";
        }
    }

    function setTitleClassName(name) {
        if (titleRef && titleRef.current) {
            titleRef.current.classList.add(name);
        }
    }

    function setDocumentName() {
        if (titleRef && titleRef.current && presenterActivityArgs && presenterActivityArgs.documentName) {
            const docName = presenterActivityArgs.documentName.trim();
            if (docName !== "") {
                const name = document.createTextNode(" - " + docName);
                titleRef.current.appendChild(name);
            } 
        }
    }

    useEffect(() => {
        if (props.setCustomActivityMessage) {
            props.setCustomActivityMessage(onCustomPresenterActivityMessage);
        }

        return () => {
            if (props.setCustomActivityMessage) {
                props.setCustomActivityMessage(null);
            }
        }
    }, [props.setCustomActivityMessage]);

    
    function onCustomPresenterActivityMessage(eventName, args, time) {
        if (eventName === "startPresentation") {
            var startPresentationData = JSON.parse(args);
            if (startPresentationData.presenterId === userId) {
                setPresenterActivityArgs(startPresentationData);
                setPresenterState(PresenterState.presenting);
            } else if (presenterState !== PresenterState.presenting) {
                setPresenterState(PresenterState.waiting);
            }
        }
        else if (eventName === "stopPresentation") {
            var stopPresentationData = JSON.parse(args);
            setPresenterActivityArgs(stopPresentationData);
            if (stopPresentationData.presenterId === userId) {
                setPresenterState(PresenterState.waiting);
            }
        }
        else if (eventName === "presentersChanged") {
            if (presentersChangedCallbackRef.current) {
                presentersChangedCallbackRef.current(eventName, args, time);
            }
            else {
                //console.log("no PresentersChanged handler registered for event " + eventName);
                presentersChangedCache[0] = { name: eventName, data: args, time: time };
            }
        }
        else {
            if (presenterPageCallbackRef.current) {
                presenterPageCallbackRef.current(eventName, args, time);
            }
            else {
                //console.log("no PageReceived handler registered for event " + eventName);
                presenterPageCache.push({ name: eventName, data: args, time: time });
            }
        }
    };
    
    function Presenter() {
        switch (presenterState) {
            case PresenterState.waiting:
                setFooterClassName('logoContainer');
                clearTitleClassName();
                return <NoDocument
                    presenters={activityArgs.presenters}
                    setPresentersChangedCallback={handlePresentersChangedCallback}
                />
            case PresenterState.presenting:
                if (error) {
                    setFooterClassName('logoContainer');
                    clearTitleClassName();
                    return <ErrorDisplayer message={error} />;
                } else {
                    addFooterClassName('not-display-if-mobile');
                    setDocumentName();
                    return <DocumentDownloader
                        pageCount={presenterActivityArgs.pageCount}
                        startPage={presenterActivityArgs.startPage}
                        paletteColors={presenterActivityArgs.paletteColors}
                        setPageReceivedCallback={handlePresenterPageCallback}
                        setTitleClassName={setTitleClassName}
                        setError={setError}
                        {...otherProps}
                    />;
                }
            case PresenterState.ready:
            default:
                clearTitleClassName();
                setFooterClassName('logoContainer');
                return <p>{t("instructions")}</p>;    
        }
    }

    return (
        <>
            <Presenter />
        </>
    );
}

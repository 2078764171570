import React, { useState, useEffect } from 'react';
import * as State from '../State';
import { useTranslate } from 'react-translate';

import './DownloadFilesActivity.css';

const inDuration = 500;
let initialState = true

export function DownloadFilesActivity(props) {
    const common = useTranslate('Common');
    const [state, setState] = useState(State.init);
    const [vote, setVote] = useState('');
    let [items, setItems] = useState([])
    const { callHubAsync, theme } = props;

    useEffect(() => {
        let cancelled = false;
        let onError = (error) => {
            initialState = true
            if (!cancelled) {
                console.error(error);
                setState(State.error);
            }
        }
        if (state === State.init) {
            initialState = true
            new Promise(r => setTimeout(r, inDuration))
                .then(() => {
                    if (!cancelled) {
                        setState(State.ready);
                    }
                })
                .catch(onError);

            return () => cancelled = true;
        }
        if (state === State.ready) {
            
            async function displayImages(choicesArray) {
                items = await displayFilesThumb(choicesArray, false)
                setItems(items)
            }

            function createElementsArray(files) {
                let elementsArray = [];
                if (files !== undefined) {
                    for (let i = 0; i < files.length; i++) {
                        elementsArray.push({
                            'name': files[i].name,
                            'fileUri': files[i].fileUri,
                            'thumbnailUri': files[i].thumbnailUri
                        });
                    }
                }
                //console.log(elementsArray)
                return elementsArray
            }

            if (initialState) {
                items = createElementsArray(props.filesArgs)
                displayImages(items);
                initialState = false
            }
        }
    }, [state, callHubAsync, setVote, setState, setItems])

    async function displayFilesThumb(filesArray, fromEvent) {
        
        let itemList = []
        
        for (let i = 0; i < filesArray.length; i++) {
            //requete pour get le fichier ?
            let file = null
            if (fromEvent)
            {
                file = await getFileAsync(filesArray[i].thumbnailUri);
            }
            else
            {
                file = await getFileAsync(filesArray[i].thumbnailUri);
            }
            //console.log(file)
            //console.log(file.type)
           if (file != null) {
               var binaryData = [];
               if (filesArray[i].name !== undefined && filesArray[i].fileUri !== undefined) {
                    binaryData.push(file);
                   let urlImage = window.URL.createObjectURL(new Blob(binaryData, { type: file.type }))
                    itemList.push(
                        /*<div key={i + "_checkButton"} className="dlFilesContainer" onClick={() => downloadFile(filesArray[i])}>
                            <img className="image" id={i + "_image"} alt="PossibleChoice" src={urlImage}></img>
                            <img className="downloadBtn" id={i + "_dlButton"} alt="PossibleChoice" src="./assets/down.png"></img>
                        </div>*/
                        <a key={i + "_checkButton"} className="dlFilesContainer" href={filesArray[i].fileUri} target="_blank">
                            <img className="image" id={i + "_image"} alt="PossibleChoice" src={urlImage}></img>
                                <svg className="downloadBtn" id={i + "_dlButton"} viewBox="0 0 1024 1024"  xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="512" cy="512" r="512" fill={theme.lightAccentColor} />
                                <path
                                    d="M160,576C160,567.333 163.167,559.833 169.5,553.5C175.833,547.167 183.333,544 192,544C200.667,544 208.167,547.167 214.5,553.5L448,786.5L448,32C448,23.3334 451.167,15.8334 457.5,9.5C463.833,3.16669 471.333,0 480,0C488.667,0 496.167,3.16669 502.5,9.5C508.833,15.8334 512,23.3334 512,32L512,786.5L745.5,553.5C751.833,547.167 759.333,544 768,544C776.667,544 784.167,547.167 790.5,553.5C796.833,559.833 800,567.333 800,576C800,584.667 796.833,592.167 790.5,598.5L504,885C500.333,888.667 496.75,891.417 493.25,893.25C489.75,895.083 485.333,896 480,896C474.667,896 470.25,895.083 466.75,893.25C463.25,891.417 459.667,888.667 456,885L169.5,598.5C163.167,592.167 160,584.667 160,576ZM800,960C808.667,960 816.167,963.167 822.5,969.5C828.833,975.833 832,983.333 832,992C832,1000.67 828.833,1008.17 822.5,1014.5C816.167,1020.83 808.667,1024 800,1024L160,1024C151.333,1024 143.833,1020.83 137.5,1014.5C131.167,1008.17 128,1000.67 128,992C128,983.333 131.167,975.833 137.5,969.5C143.833,963.167 151.333,960 160,960Z"
                                    fill="white"
                                    transform="scale(0.7) translate(230,180)" />
                                </svg>
                        </a>
                    )
               }
           }
            
        }
        if (itemList.length === 0) {
            itemList.push(
                <div key="noChoices">En attente de fichiers</ div>)
        }
        
        setItems(itemList)
        
        return itemList;
    }

    async function getFileAsync(fileUri) {
        return await fetch(fileUri, { method: 'GET' })
            .then((response) => response.blob())
            .then(data => { return data; });
    }    

    return (
        <form>
            <div className="wrapper">
                {items}
            </div>

            <p style={{ opacity: state === State.error ? 1 : 0 }}> {/* just hidden to keep layout */}
                {common('error.retry')}
            </p>
        </form>
    );
}

    
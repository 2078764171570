import React from 'react';

export function DeleteIcon(props) {
    return (
        <svg
            className="fileImage"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={props.size ?? 20}
            height={props.size ?? 20}
            style={{
                maxWidth: props.maxSize,
                maxHeight: props.maxSize,
                fill: '#ffffff',
                stroke: 'none',
                margin: '5px 0'
            }}
            viewBox="0 0 100 100">
            <path d="M 42.4375 0 C 38.227196 0 34.724609 3.4948683 34.724609 7.7070312 L 34.724609 12.322266 L 7.3378906 12.322266 A 3.4798482 3.4798482 0 1 0 7.3378906 19.28125 L 12.367188 19.28125 L 12.367188 85.080078 C 12.367188 93.288477 19.090852 100 27.302734 100 L 72.697266 100 C 80.909149 100 87.632813 93.288477 87.632812 85.080078 L 87.632812 19.28125 L 92.662109 19.28125 A 3.4798482 3.4798482 0 1 0 92.662109 12.322266 L 65.275391 12.322266 L 65.275391 7.7070312 C 65.275391 3.4948694 61.772804 5.9211895e-16 57.5625 0 L 42.4375 0 z M 42.4375 6.9589844 L 57.5625 6.9589844 C 58.042878 6.9589844 58.316406 7.234777 58.316406 7.7070312 L 58.316406 12.322266 L 41.683594 12.322266 L 41.683594 7.7070312 C 41.683594 7.2347781 41.957122 6.9589844 42.4375 6.9589844 z M 19.326172 19.28125 L 80.673828 19.28125 L 80.673828 85.080078 C 80.673828 89.548567 77.179221 93.041016 72.697266 93.041016 L 27.302734 93.041016 C 22.820781 93.041016 19.326172 89.548567 19.326172 85.080078 L 19.326172 19.28125 z M 33 31.84375 A 3.4798482 3.4798482 0 0 0 29.574219 35.371094 L 29.574219 75.623047 A 3.4798482 3.4798482 0 1 0 36.533203 75.623047 L 36.533203 35.371094 A 3.4798482 3.4798482 0 0 0 33 31.84375 z M 49.947266 31.84375 A 3.4798482 3.4798482 0 0 0 46.519531 35.371094 L 46.519531 75.623047 A 3.480469 3.480469 0 1 0 53.480469 75.623047 L 53.480469 35.371094 A 3.4798482 3.4798482 0 0 0 49.947266 31.84375 z M 66.894531 31.84375 A 3.4798482 3.4798482 0 0 0 63.466797 35.371094 L 63.466797 75.623047 A 3.4798482 3.4798482 0 1 0 70.425781 75.623047 L 70.425781 35.371094 A 3.4798482 3.4798482 0 0 0 66.894531 31.84375 z " />
        </svg >);
}
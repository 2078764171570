import React from 'react';
import { useTranslate } from "react-translate";
import { ExclamationTriangleIcon } from 'react-line-awesome'

export function ErrorDisplayer({ message }) {
    const t = useTranslate("Common");

    setTimeout(() => {
        window.location.reload();
    }, 5000);

    return (
        <>
            <ExclamationTriangleIcon className='error-icon' />
            <p>{message}</p>
            <p>{t("error.refresh")}</p>
        </>
    );
}
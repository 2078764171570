import React from 'react';

export function Logo(props) {
    return (
        <svg
            className="cduxLogo"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={props.size ?? 100}
            height={props.size ?? 100}
            style={{ maxWidth: props.maxSize, maxHeight: props.maxSize }}
            viewBox="0 0 300 300">
            <path
                style={{ strokeOpacity: 0.50588238 }}
                d="m 240.35037,179.35661 a 95,95 0 0 1 -111.63012,63.22942 95,95 0 0 1 -72.821415,-105.62332" />
            <path
                style={{ strokeOpacity: 0.3137255 }}
                d="m 70.883257,97.411589 a 95,95 0 0 1 77.222173,-42.392696 95,95 0 0 1 78.85127,39.279527"
             />
            <path
                style={{ strokeOpacity: 0.90366971 }}
                d="m 167.3963,243.39362 a 95,95 0 0 1 -83.102277,-24.78076 95,95 0 0 1 -28.35282,-81.95238" />
            <path
                style={{ strokeOpacity: 0.93119264 }}
                d="m 77.754203,88.310902 a 95,95 0 0 1 76.555047,-33.213117 95,95 0 0 1 73.22999,40.014449" />
            <path
                style={{ strokeOpacity: 0.68627453 }}
                d="m 154.3944,85.148714 a 65,65 0 0 1 60.60482,64.533266 65,65 0 0 1 -59.97045,65.1232 65,65 0 0 1 -69.298953,-55.09212" />
            <path
                style={{ strokeOpacity: 0.7019608 }}
                d="m 87.792716,131.15182 a 65,65 0 0 1 49.818384,-44.960246 65,65 0 0 1 63.02333,23.051156 65,65 0 0 1 9.05367,66.49306"
             />
            <path
                style={{ strokeOpacity: 0.74901962 }}
                d="m 183.98934,150.85139 a 34,34 0 0 1 -18.68958,29.5117 34,34 0 0 1 -34.83957,-2.53871 34,34 0 0 1 -14.2139,-31.90934" />
    </svg >);
}
//utils for ToolBar and ToolPalette ans some highers components

import React from 'react';
import './Tools.css';

export const colors = {
    YELLOW: '#f4d000',
    WHITE: '#fcfcfc',
    TURQUOISE: '#00c5d5',
    ORANGE: '#f45b00',
    BLACK: '#000000',
    BLUE: '#0055d5',
    RED: '#ff0000',
    EXCENSE: '#e43b5c',
    GREEN: '#008000',
}

export const sizes = {
    SMALL: 2,
    MEDIUM: 4,
    LARGE: 10
}

export const types = {
    HAND: 'hand',
    PEN: 'pen',
    ERASER: 'eraser',
    TEXT: 'text'
}

export const defaultTool = {
    color: colors.RED,
    size: sizes.SMALL,
    type: types.PEN
}

export function Action(props) {
    const { tool, setTool, target, value, disabled } = props;

    function click() {
        setTool(previousTool => {
            var newTool = { ...previousTool };
            newTool[target] = value;
            return newTool;
        });
    }

    const isSelected = tool[target] === value;

    return (
        <button
            disabled={disabled}
            onClick={click}
            className={isSelected ? 'selected' : ''} >
            {props.children}
        </button>
    );
}

export function ActionColor(props) {

    const { tool, setTool, className, color, background, disabled } = props;

    function click() {
        setTool(previousTool => {
            var newTool = { ...previousTool };
            newTool["color"] = color;
            return newTool;
        });
    }

    const isSelected = (tool["color"] === color);

    return (
        <div className={className}>
            <button
                disabled={disabled}
                onClick={click}>
                {isSelected
                    ? (<div className="color-circle" style={{ background: color, display: 'flex' }}>
                        <div className="color-circle small" style={{ background: color, borderColor: background }}></div>
                    </div>)
                    : (<div className="color-circle" style={{ background: color }}></div>)
                }
            </button>
        </div>
    );
}
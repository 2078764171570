import React, { useContext } from 'react';
import { ThemeContext, defaultTheme } from '../../../Theme';
import './Tools.css';

export function PencilIcon(props) {

    const { isSelected, color } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    return (
        <div style={{
            display: 'grid',
            objectFit: 'contain'
        }}>
            <svg className='icon-svg'
                alt='pencil-color-fill'
                xmlns="http://www.w3.org/2000/svg"
                style={{
                   zIndex: '3',
                }}
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 192 261">
                <path id="Sélection"
                    fill={isSelected ? color : 'none'}
                    stroke="none"
                    strokeWidth="1"
                    d="M 1.00,1.00
                       C 1.00,1.00 191.00,1.00 191.00,1.00
                         191.00,1.00 191.00,74.00 191.00,74.00
                         191.00,74.00 154.00,55.75 154.00,55.75
                         150.25,53.88 141.92,48.84 138.00,49.35
                         135.70,49.65 130.32,52.59 128.00,53.75
                         128.00,53.75 107.00,64.25 107.00,64.25
                         104.12,65.69 99.13,68.58 96.00,68.58
                         92.87,68.58 87.88,65.69 85.00,64.25
                         85.00,64.25 64.00,53.75 64.00,53.75
                         60.47,51.99 54.95,48.40 51.00,49.17
                         48.82,49.60 39.62,54.44 37.00,55.75
                         37.00,55.75 1.00,73.00 1.00,73.00
                         1.00,73.00 1.00,1.00 1.00,1.00 Z
                       M 48.00,165.00
                       C 48.00,165.00 143.00,165.00 143.00,165.00
                         143.00,165.00 125.25,202.00 125.25,202.00
                         125.25,202.00 96.00,260.00 96.00,260.00
                         96.00,260.00 77.75,225.00 77.75,225.00
                         77.75,225.00 58.75,187.00 58.75,187.00
                         58.75,187.00 48.00,165.00 48.00,165.00 Z" />
            </svg>
            <svg className='icon-svg'
                alt='pencil-white-fill'
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    zIndex: '4',
                }}
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 209 278">
                <path id="Sélection"
                    fill={isSelected ? "white" : "none"}
                    stroke="none"
                    strokeWidth="1"
                    d="M 24.00,77.00
                       C 24.00,77.00 50.00,63.75 50.00,63.75
                         53.79,61.86 58.68,58.74 63.00,59.43
                         65.74,59.87 71.30,62.90 74.00,64.25
                         74.00,64.25 94.00,74.25 94.00,74.25
                         96.70,75.60 102.15,78.73 105.00,78.73
                         107.85,78.73 113.30,75.60 116.00,74.25
                         116.00,74.25 136.00,64.25 136.00,64.25
                         138.64,62.93 144.33,59.81 147.00,59.49
                         150.75,59.05 155.66,62.08 159.00,63.75
                         159.00,63.75 185.00,77.00 185.00,77.00
                         185.00,77.00 174.25,100.00 174.25,100.00
                         174.25,100.00 155.25,138.00 155.25,138.00
                         155.25,138.00 145.61,154.98 145.61,154.98
                         145.61,154.98 138.00,156.00 138.00,156.00
                         138.00,156.00 71.00,156.00 71.00,156.00
                         71.00,156.00 63.63,155.01 63.63,155.01
                         63.63,155.01 53.75,137.00 53.75,137.00
                         53.75,137.00 34.75,99.00 34.75,99.00
                         34.75,99.00 24.00,77.00 24.00,77.00 Z" />
            </svg>
            <svg className='icon-svg'
                alt='pencil-stroke'
                xmlns="http://www.w3.org/2000/svg"
                style={{
                   zIndex: '5',
                }}
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 209 278">
                <path id="Sélection"
                    fill={isSelected ? theme.darkDarkColor : theme.lightColor}
                    stroke="none"
                    strokeWidth="1"
                    d="M 1.00,1.00
                       C 1.00,1.00 18.00,1.00 18.00,1.00
                         18.00,1.00 18.00,60.00 18.00,60.00
                         18.00,60.00 49.00,44.75 49.00,44.75
                         52.73,42.89 58.85,38.96 63.00,39.74
                         63.00,39.74 94.00,55.25 94.00,55.25
                         96.21,56.35 102.26,59.80 104.42,59.78
                         106.67,59.77 112.73,56.38 115.00,55.25
                         115.00,55.25 136.00,44.75 136.00,44.75
                         138.43,43.54 144.67,40.15 147.00,39.84
                         150.67,39.36 157.56,43.53 161.00,45.25
                         168.07,48.78 185.57,58.17 192.00,60.00
                         192.00,60.00 192.00,1.00 192.00,1.00
                         192.00,1.00 209.00,1.00 209.00,1.00
                         209.00,1.00 208.00,20.00 208.00,20.00
                         208.00,20.00 208.00,64.00 208.00,64.00
                         207.98,74.81 203.97,79.55 199.25,89.00
                         199.25,89.00 179.25,129.00 179.25,129.00
                         179.25,129.00 131.25,225.00 131.25,225.00
                         131.25,225.00 105.00,277.00 105.00,277.00
                         105.00,277.00 89.75,248.00 89.75,248.00
                         89.75,248.00 62.75,194.00 62.75,194.00
                         62.75,194.00 18.75,106.00 18.75,106.00
                         18.75,106.00 1.63,71.00 1.63,71.00
                         1.63,71.00 1.00,63.00 1.00,63.00
                         1.00,63.00 1.00,1.00 1.00,1.00 Z
                       M 186.00,76.00
                       C 186.00,76.00 160.00,63.25 160.00,63.25
                         156.52,61.50 150.86,58.03 147.00,58.49
                         144.33,58.81 138.64,61.93 136.00,63.25
                         136.00,63.25 116.00,73.25 116.00,73.25
                         113.30,74.60 107.85,77.73 105.00,77.73
                         102.05,77.73 95.87,74.17 93.00,72.76
                         93.00,72.76 74.00,63.75 74.00,63.75
                         71.22,62.36 64.76,58.82 62.00,58.49
                         57.84,58.00 52.54,61.58 49.00,63.58
                         49.00,63.58 24.00,76.00 24.00,76.00
                         24.00,76.00 33.75,98.00 33.75,98.00
                         33.75,98.00 54.25,139.00 54.25,139.00
                         54.25,139.00 63.63,156.01 63.63,156.01
                         63.63,156.01 71.00,157.00 71.00,157.00
                         71.00,157.00 138.00,157.00 138.00,157.00
                         138.00,157.00 145.61,155.98 145.61,155.98
                         145.61,155.98 155.25,139.00 155.25,139.00
                         155.25,139.00 175.25,99.00 175.25,99.00
                         175.25,99.00 186.00,76.00 186.00,76.00 Z
                       M 137.00,174.00
                       C 137.00,174.00 72.00,174.00 72.00,174.00
                         72.00,174.00 91.75,214.00 91.75,214.00
                         91.75,214.00 105.00,239.00 105.00,239.00
                         105.00,239.00 125.25,199.00 125.25,199.00
                         125.25,199.00 137.00,174.00 137.00,174.00 Z" />
            </svg>
        </div>);
}

export function EraserIcon(props) {

    const { isSelected } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    return (
        <div style={{
            display: 'grid',
            objectFit: 'contain'
        }}>
            <svg className='icon-svg'
                alt='eraser-color-fill'
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    zIndex: '3',
                }}
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 262 238">
                <path id="Sélection"
                    fill={isSelected ? theme.darkColor : 'none'}
                    stroke="none"
                    strokeWidth="1"
                    d="M 238.00,103.00
                       C 238.00,103.00 174.00,167.00 174.00,167.00
                         174.00,167.00 157.00,184.00 157.00,184.00
                         155.26,185.74 151.27,190.10 149.00,190.66
                         146.52,191.28 144.69,189.45 143.00,187.94
                         143.00,187.94 130.00,175.00 130.00,175.00
                         130.00,175.00 86.00,131.00 86.00,131.00
                         86.00,131.00 75.02,120.17 75.02,120.17
                         73.63,118.85 71.20,117.01 71.20,114.91
                         71.20,112.77 73.64,110.45 75.02,109.00
                         75.02,109.00 88.00,96.00 88.00,96.00
                         88.00,96.00 140.00,44.00 140.00,44.00
                         140.00,44.00 154.00,30.02 154.00,30.02
                         155.84,28.27 158.17,25.63 161.00,26.34
                         163.12,26.87 166.41,30.42 168.00,32.00
                         168.00,32.00 182.00,46.00 182.00,46.00
                         182.00,46.00 218.00,82.00 218.00,82.00
                         223.69,87.69 233.64,96.91 238.00,103.00 Z" />
            </svg>
            <svg className='icon-svg'
                alt='eraser-white-fill'
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    zIndex: '4',
                }}
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 262 238">
                <path id="Sélection"
                    fill={isSelected ? "white" : "none"}
                    stroke="none"
                    strokeWidth="1"
                    d="M 57.00,127.00
                       C 63.59,131.72 82.04,151.04 89.00,158.00
                         89.00,158.00 121.00,190.00 121.00,190.00
                         123.50,192.50 132.97,201.24 133.66,204.00
                         134.49,207.32 130.09,210.90 128.00,213.00
                         125.94,215.07 122.52,218.72 120.00,219.98
                         116.61,221.67 102.57,221.00 98.00,221.00
                         98.00,221.00 72.00,221.00 72.00,221.00
                         62.78,220.94 61.29,217.29 55.00,211.00
                         55.00,211.00 35.00,191.00 35.00,191.00
                         35.00,191.00 22.02,178.00 22.02,178.00
                         19.92,175.81 17.29,173.27 17.29,170.00
                         17.29,165.57 23.98,160.02 27.00,157.00
                         27.00,157.00 57.00,127.00 57.00,127.00 Z" />
            </svg>
            <svg className='icon-svg'
                alt='eraser-stroke'
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    zIndex: '5',
                }}
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 262 238">
                <path id="Sélection"
                    fill={isSelected ? theme.darkDarkColor : theme.lightColor}
                    stroke="none"
                    strokeWidth="1"
                    d="M 197.00,237.00
                       C 197.00,237.00 66.00,237.00 66.00,237.00
                         63.91,237.00 60.94,237.12 59.00,236.40
                         55.10,234.97 46.38,225.38 43.00,222.00
                         43.00,222.00 15.00,194.00 15.00,194.00
                         5.98,184.98 -2.79,177.15 1.62,163.00
                         3.57,156.76 8.53,152.47 13.00,148.00
                         13.00,148.00 36.00,125.00 36.00,125.00
                         36.00,125.00 47.01,113.00 47.01,113.00
                         47.01,113.00 121.00,39.00 121.00,39.00
                         121.00,39.00 147.00,13.00 147.00,13.00
                         149.26,10.74 156.37,2.99 159.00,2.34
                         161.83,1.63 164.16,4.27 166.00,6.02
                         166.00,6.02 182.00,22.00 182.00,22.00
                         182.00,22.00 242.00,82.00 242.00,82.00
                         242.00,82.00 255.98,96.00 255.98,96.00
                         257.73,97.84 260.37,100.17 259.66,103.00
                         259.05,105.47 253.01,110.99 251.00,113.00
                         251.00,113.00 228.00,136.00 228.00,136.00
                         228.00,136.00 143.00,221.00 143.00,221.00
                         143.00,221.00 197.00,221.00 197.00,221.00
                         197.00,221.00 197.00,237.00 197.00,237.00 Z
                       M 239.00,102.00
                       C 239.00,102.00 183.00,46.00 183.00,46.00
                         183.00,46.00 168.00,31.00 168.00,31.00
                         166.41,29.42 163.12,25.87 161.00,25.34
                         158.17,24.63 155.84,27.27 154.00,29.02
                         154.00,29.02 139.00,44.00 139.00,44.00
                         139.00,44.00 87.00,96.00 87.00,96.00
                         87.00,96.00 74.02,109.00 74.02,109.00
                         72.58,110.51 70.20,112.76 70.20,115.00
                         70.20,118.07 76.79,123.79 79.00,126.00
                         79.00,126.00 105.00,151.00 105.00,151.00
                         105.00,151.00 136.00,182.00 136.00,182.00
                         138.33,184.33 144.84,191.80 148.00,191.80
                         150.69,191.80 154.18,187.80 156.00,186.00
                         156.00,186.00 172.00,170.00 172.00,170.00
                         172.00,170.00 215.00,127.00 215.00,127.00
                         221.16,120.84 234.49,108.30 239.00,102.00 Z
                       M 58.00,126.00
                       C 51.14,130.91 41.60,141.65 35.00,147.96
                         35.00,147.96 26.00,156.04 26.00,156.04
                         22.85,159.06 16.87,164.53 16.33,169.00
                         15.87,172.85 18.58,175.45 21.02,178.00
                         21.02,178.00 35.00,192.00 35.00,192.00
                         35.00,192.00 56.00,213.00 56.00,213.00
                         61.28,218.27 63.18,221.85 71.00,222.00
                         71.00,222.00 112.00,222.00 112.00,222.00
                         122.12,221.98 122.96,219.19 130.00,212.00
                         132.37,209.58 136.04,206.73 134.26,203.00
                         133.36,201.13 128.68,196.68 127.00,195.00
                         127.00,195.00 111.00,179.00 111.00,179.00
                         111.00,179.00 58.00,126.00 58.00,126.00 Z" />
            </svg>
        </div>);
}

export function PaletteIcon(props) {

    return (
        <div style={{
            display: 'grid',
            objectFit: 'contain'
        }}>
            <svg className='icon-svg'
                xmlns="http://www.w3.org/2000/svg"
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 105 105">
                <path id="Sélection"
                    fill="white"
                    stroke="none"
                    strokeWidth="1"
                    d="M 51.00,0.51
                       C 58.71,0.36 66.91,1.78 74.00,4.87
                         79.90,7.45 85.47,11.42 89.96,16.01
                         99.40,25.68 105.36,40.39 104.49,54.00
                         102.66,82.60 83.20,103.51 54.00,104.41
                         44.42,104.70 39.05,98.39 40.18,89.00
                         41.23,80.37 51.52,72.79 41.87,63.13
                         29.19,50.46 16.96,73.81 4.34,61.61
                         -1.42,56.04 0.64,43.93 2.72,37.00
                         8.03,19.31 22.06,6.19 40.00,1.90
                         40.00,1.90 51.00,0.51 51.00,0.51 Z
                       M 48.00,6.29
                       C 29.02,9.43 13.51,19.54 8.40,39.00
                         6.80,43.94 4.25,53.30 8.40,57.26
                         14.85,62.84 24.60,53.89 31.00,53.20
                         40.94,52.14 50.84,61.29 51.91,71.00
                         52.67,79.77 38.18,95.50 51.91,98.71
                         53.34,98.90 54.65,98.91 56.00,98.71
                         61.86,98.40 69.89,96.00 75.00,93.13
                         80.69,89.92 85.32,86.27 89.24,81.00
                         113.41,48.54 88.92,2.42 48.00,6.29 Z
                       M 44.11,20.78
                       C 53.15,19.48 53.58,29.37 47.94,31.38
                         42.11,33.47 36.30,25.57 44.11,20.78 Z
                       M 69.23,28.03
                       C 78.35,24.93 80.57,34.83 74.93,37.81
                         69.36,40.75 62.57,34.16 69.23,28.03 Z
                       M 25.04,33.84
                       C 33.81,33.27 33.31,43.14 27.89,44.55
                         21.77,46.15 16.57,38.38 25.04,33.84 Z
                       M 76.15,54.04
                       C 84.86,51.44 86.89,60.80 81.77,63.82
                         76.35,67.02 69.24,60.10 76.15,54.04 Z
                       M 63.23,73.74
                       C 73.26,71.46 73.36,82.29 66.96,84.16
                         61.56,85.72 56.96,78.25 63.23,73.74 Z" />
            </svg>
        </div>);
}

export function HandIcon(props) {

    return (
        <div style={{
            display: 'grid',
            objectFit: 'contain'
        }}>
            <svg className='icon-svg'
                xmlns="http://www.w3.org/2000/svg"
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 225 225">
                <path id="Sélection"
                    fill="white"
                    stroke="none"
                    strokeWidth="1"
                    d="M 99.00,131.00
                       C 99.00,131.00 99.00,23.00 99.00,23.00
                       C 99.00,131.00 99.00,23.00 99.00,23.00
                         99.14,11.02 105.21,1.58 118.00,0.76
                         127.67,0.14 136.46,5.47 139.21,15.00
                         140.36,18.98 140.00,26.59 140.00,31.00
                         140.00,31.00 140.00,61.00 140.00,61.00
                         140.00,63.24 139.79,66.79 141.02,68.70
                         142.59,71.12 146.38,71.71 149.00,72.37
                         149.00,72.37 167.00,76.87 167.00,76.87
                         167.00,76.87 200.00,85.13 200.00,85.13
                         207.01,86.88 214.67,88.31 219.61,94.09
                         224.44,99.74 224.99,107.89 225.00,115.00
                         225.00,115.00 225.00,145.00 225.00,145.00
                         225.00,170.39 223.97,191.33 202.99,209.25
                         198.88,212.75 194.84,215.61 190.00,218.03
                         168.55,228.76 140.68,225.68 121.00,212.48
                         108.80,204.30 91.08,185.08 80.00,174.00
                         74.43,168.43 55.33,150.36 52.41,145.00
                         50.32,141.15 49.85,137.31 50.04,133.00
                         50.69,118.09 65.38,109.43 79.00,114.56
                         86.15,117.25 93.60,125.60 99.00,131.00 Z
                       M 155.00,28.00
                       C 155.00,28.00 161.00,28.00 161.00,28.00
                         167.34,28.08 179.35,31.38 182.22,22.96
                         185.02,14.74 173.49,14.40 170.75,10.72
                         167.96,6.97 170.22,1.07 176.00,0.63
                         192.91,-0.64 201.96,21.43 192.06,33.83
                         183.40,44.68 167.43,42.00 155.00,42.00
                         155.00,42.00 155.00,28.00 155.00,28.00 Z
                       M 140.00,84.00
                       C 140.00,86.96 140.29,91.30 138.83,93.92
                         136.19,98.57 131.49,98.10 128.60,93.92
                         126.74,91.27 127.01,88.09 127.00,85.00
                         127.00,85.00 127.00,25.00 127.00,25.00
                         126.93,20.46 126.36,15.48 120.98,14.33
                         112.69,12.56 112.01,21.36 112.00,27.00
                         112.00,27.00 112.00,130.00 112.00,130.00
                         112.00,132.53 112.11,135.59 111.35,138.00
                         110.79,139.79 109.84,141.47 108.61,142.89
                         97.21,156.04 84.60,133.01 76.00,127.74
                         69.29,123.62 61.90,129.27 63.33,136.00
                         64.12,139.71 68.37,143.37 71.00,146.00
                         71.00,146.00 88.99,164.00 88.99,164.00
                         104.29,179.68 118.97,199.32 140.00,207.54
                         172.61,220.29 210.94,199.89 211.00,163.00
                         211.00,163.00 211.00,112.00 211.00,112.00
                         211.00,109.56 211.19,106.27 209.98,104.11
                         207.43,99.57 196.93,98.11 192.00,96.87
                         192.00,96.87 140.00,84.00 140.00,84.00 Z
                       M 84.00,42.00
                       C 84.00,42.00 37.00,42.00 37.00,42.00
                         25.45,42.14 14.94,49.91 14.10,62.00
                         13.43,71.60 19.25,79.53 28.00,83.07
                         32.97,85.08 39.59,84.48 41.16,90.02
                         43.12,96.95 34.80,98.38 30.00,97.70
                         20.27,96.32 12.17,91.00 6.57,83.00
                         -8.48,61.49 5.96,29.33 33.00,29.00
                         33.00,29.00 46.00,29.00 46.00,29.00
                         46.00,29.00 61.00,28.00 61.00,28.00
                         61.00,28.00 84.00,28.00 84.00,28.00
                         84.00,28.00 84.00,42.00 84.00,42.00 Z" />
            </svg>
        </div>);}

export function StylusIcon(props) {
    return (
        <div style={{
            display: 'grid',
            objectFit: 'contain'
        }}>
            <svg className='icon-svg'
                xmlns="http://www.w3.org/2000/svg"
                width={props.size ?? 25}
                height={props.size ?? 25}
                viewBox="0 0 215 225">
                <path id="Sélection"
                    fill="white"
                    stroke="none"
                    strokeWidth="1"
                    d="M 91.00,15.20
                       C 93.48,9.27 96.37,2.68 103.00,0.98
                         109.46,-0.67 124.73,6.66 128.26,12.04
                         129.82,14.42 130.13,17.23 129.89,20.00
                         129.49,24.83 127.18,27.13 126.91,30.00
                         126.55,33.76 129.35,35.40 128.32,40.00
                         128.32,40.00 121.25,55.00 121.25,55.00
                         121.25,55.00 105.25,87.00 105.25,87.00
                         105.25,87.00 63.25,171.00 63.25,171.00
                         63.25,171.00 50.04,196.79 50.04,196.79
                         47.78,199.73 44.23,201.14 41.00,202.75
                         34.00,206.26 27.09,210.77 19.00,210.00
                         17.49,213.51 14.71,220.15 11.79,222.48
                         7.65,225.76 2.54,223.76 1.47,218.98
                         0.31,213.82 5.55,208.56 5.99,204.00
                         5.99,204.00 5.02,196.00 5.02,196.00
                         5.02,196.00 5.02,180.00 5.02,180.00
                         5.00,177.32 4.90,174.60 5.63,172.00
                         5.63,172.00 21.75,139.00 21.75,139.00
                         21.75,139.00 52.25,78.00 52.25,78.00
                         52.25,78.00 68.00,45.00 68.00,45.00
                         68.00,45.00 61.00,42.00 61.00,42.00
                         61.00,42.00 49.25,66.00 49.25,66.00
                         49.25,66.00 41.75,81.00 41.75,81.00
                         39.84,84.82 35.82,93.93 32.67,96.26
                         28.93,99.04 24.25,97.32 22.78,92.98
                         21.71,89.82 23.41,86.77 24.76,84.00
                         24.76,84.00 32.75,68.00 32.75,68.00
                         32.75,68.00 45.75,42.00 45.75,42.00
                         48.19,37.14 50.45,31.63 56.01,29.70
                         62.63,27.41 69.39,30.82 75.00,34.00
                         76.38,29.17 81.38,17.43 86.04,15.20
                         87.94,14.58 89.08,14.85 91.00,15.20 Z
                       M 116.00,18.00
                       C 116.00,18.00 106.00,14.00 106.00,14.00
                         106.00,14.00 103.00,21.00 103.00,21.00
                         103.00,21.00 113.00,26.00 113.00,26.00
                         113.00,26.00 116.00,18.00 116.00,18.00 Z
                       M 92.00,30.00
                       C 92.00,30.00 73.75,65.00 73.75,65.00
                         73.75,65.00 37.75,137.00 37.75,137.00
                         37.75,137.00 24.75,163.00 24.75,163.00
                         21.32,169.87 18.11,174.13 18.00,182.00
                         17.97,184.67 17.51,193.56 19.02,195.46
                         20.70,197.43 23.96,196.30 26.00,195.46
                         31.61,193.44 38.11,190.25 41.20,184.96
                         41.20,184.96 52.25,163.00 52.25,163.00
                         52.25,163.00 75.25,117.00 75.25,117.00
                         75.25,117.00 99.25,69.00 99.25,69.00
                         99.25,69.00 113.00,41.00 113.00,41.00
                         113.00,41.00 92.00,30.00 92.00,30.00 Z
                       M 108.00,183.42
                       C 119.32,182.04 131.58,185.42 141.00,191.75
                         155.80,201.70 156.98,211.24 178.00,211.00
                         186.30,210.90 191.38,208.05 198.00,203.25
                         201.52,200.68 206.02,195.88 210.79,198.35
                         218.17,202.18 212.16,210.10 207.98,213.48
                         199.54,220.32 185.94,225.19 175.00,224.49
                         163.93,223.78 157.32,221.94 148.00,215.64
                         141.64,211.34 140.02,208.41 135.00,204.53
                         126.46,197.92 117.66,195.71 107.00,197.17
                         96.52,198.61 88.11,206.90 81.00,214.00
                         78.22,216.78 72.68,223.02 69.00,223.92
                         64.75,224.96 60.73,221.45 61.36,217.00
                         61.80,213.81 64.97,210.29 67.09,208.00
                         79.74,194.32 89.35,186.24 108.00,183.42 Z" />
            </svg>
        </div>);
}
import React, { useState, useEffect } from 'react';
import * as State from '../State';
import { useTranslate } from 'react-translate';
import './TrueFalseActivity.css';

const delayBetweenLikes = 10;
const outDuration = 10;
const pathCheckSelected = "M0,480C0,436 5.75,393.583 17.25,352.75C28.75,311.917 44.9167,273.667 65.75,238C86.5833,202.333 111.583,169.917 140.75,140.75C169.917,111.583 202.333,86.5834 238,65.75C273.667,44.9167 311.917,28.75 352.75,17.25C393.583,5.75 436,0 480,0C524,0 566.417,5.75 607.25,17.25C648.083,28.75 686.25,44.9167 721.75,65.75C757.25,86.5834 789.667,111.667 819,141C848.333,170.333 873.417,202.75 894.25,238.25C915.083,273.75 931.25,311.917 942.75,352.75C954.25,393.583 960,436 960,480C960,524 954.25,566.417 942.75,607.25C931.25,648.083 915.083,686.333 894.25,722C873.417,757.667 848.417,790.083 819.25,819.25C790.083,848.417 757.667,873.417 722,894.25C686.333,915.083 648.083,931.25 607.25,942.75C566.417,954.25 524,960 480,960C435.667,960 393.083,954.25 352.25,942.75C311.417,931.25 273.25,915.083 237.75,894.25C202.25,873.417 169.917,848.417 140.75,819.25C111.583,790.083 86.5833,757.75 65.75,722.25C44.9167,686.75 28.75,648.5 17.25,607.5C5.75,566.5 0,524 0,480ZM416,712C421,712 426.083,711 431.25,709C436.417,707 440.833,704.167 444.5,700.5L828.5,316.5C836.167,308.833 840,299.333 840,288C840,276.667 836.167,267.167 828.5,259.5C820.833,251.833 811.333,248 800,248C788.667,248 779.167,251.833 771.5,259.5L416,615.5L252.5,451.5C244.833,443.833 235.333,440 224,440C212.667,440 203.167,443.833 195.5,451.5C187.833,459.167 184,468.667 184,480C184,485 185,490.083 187,495.25C189,500.417 191.833,504.833 195.5,508.5L387.5,700.5C391.167,704.167 395.583,707 400.75,709C405.917,711 411,712 416,712Z"
const pathCheckUnselected = "M192,480C192,471.333 195.167,463.833 201.5,457.5C207.833,451.167 215.333,448 224,448C232.667,448 240.167,451.167 246.5,457.5L416,626.5L777.5,265.5C783.833,259.167 791.333,256 800,256C808.667,256 816.167,259.167 822.5,265.5C828.833,271.833 832,279.333 832,288C832,296.667 828.833,304.167 822.5,310.5L438.5,694.5C432.167,700.833 424.667,704 416,704C407.333,704 399.833,700.833 393.5,694.5L201.5,502.5C195.167,496.167 192,488.667 192,480Z"
const pathCrossSelected = "M0,480C0,436 5.75,393.583 17.25,352.75C28.75,311.917 44.9167,273.667 65.75,238C86.5833,202.333 111.583,169.917 140.75,140.75C169.917,111.583 202.333,86.5834 238,65.75C273.667,44.9167 311.917,28.75 352.75,17.25C393.583,5.75 436,0 480,0C524,0 566.417,5.75 607.25,17.25C648.083,28.75 686.25,44.9167 721.75,65.75C757.25,86.5834 789.667,111.667 819,141C848.333,170.333 873.417,202.75 894.25,238.25C915.083,273.75 931.25,311.917 942.75,352.75C954.25,393.583 960,436 960,480C960,524 954.25,566.417 942.75,607.25C931.25,648.083 915.083,686.333 894.25,722C873.417,757.667 848.417,790.083 819.25,819.25C790.083,848.417 757.667,873.417 722,894.25C686.333,915.083 648.083,931.25 607.25,942.75C566.417,954.25 524,960 480,960C435.667,960 393.083,954.25 352.25,942.75C311.417,931.25 273.25,915.083 237.75,894.25C202.25,873.417 169.917,848.417 140.75,819.25C111.583,790.083 86.5833,757.75 65.75,722.25C44.9167,686.75 28.75,648.5 17.25,607.5C5.75,566.5 0,524 0,480ZM304,696C309,696 314.083,695 319.25,693C324.417,691 328.833,688.167 332.5,684.5L480,536.5L627.5,684.5C631.167,688.167 635.583,691 640.75,693C645.917,695 651,696 656,696C667.333,696 676.833,692.167 684.5,684.5C692.167,676.833 696,667.333 696,656C696,644.667 692.167,635.167 684.5,627.5L536.5,480L684.5,332.5C692.167,324.833 696,315.333 696,304C696,292.667 692.167,283.167 684.5,275.5C676.833,267.833 667.333,264 656,264C644.667,264 635.167,267.833 627.5,275.5L480,423.5L332.5,275.5C324.833,267.833 315.333,264 304,264C292.667,264 283.167,267.833 275.5,275.5C267.833,283.167 264,292.667 264,304C264,309 265,314.083 267,319.25C269,324.417 271.833,328.833 275.5,332.5L423.5,480L275.5,627.5C271.833,631.167 269,635.583 267,640.75C265,645.917 264,651 264,656C264,667 267.917,676.417 275.75,684.25C283.583,692.083 293,696 304,696Z"
const pathCrossUnselected = "M 688 304 C 688 312.667 684.833 320.167 678.5 326.5 L 525 480 L 678.5 633.5 C 684.833 639.833 688 647.333 688 656 C 688 664.667 684.833 672.167 678.5 678.5 C 672.167 684.833 664.667 688 656 688 C 647.333 688 639.833 684.833 633.5 678.5 L 480 525 L 326.5 678.5 C 320.167 684.833 312.667 688 304 688 C 295.333 688 287.833 684.833 281.5 678.5 C 275.167 672.167 272 664.667 272 656 C 272 647.333 275.167 639.833 281.5 633.5 L 435 480 L 281.5 326.5 C 275.167 320.167 272 312.667 272 304 C 272 295.333 275.167 287.833 281.5 281.5 C 287.833 275.167 295.333 272 304 272 C 312.667 272 320.167 275.167 326.5 281.5 L 480 435 L 633.5 281.5 C 639.833 275.167 647.333 272 656 272 C 664.667 272 672.167 275.167 678.5 281.5 C 684.833 287.833 688 295.333 688 304 Z"

export function TrueFalseActivity(props) {
    const t = useTranslate('TrueFalse');
    return ActivityBase(props, t);    
}

export function YesNoActivity(props) {
    const t = useTranslate('YesNo');
    return ActivityBase(props, t);
}

export function ActivityBase(props, t) {
    const [state, setState] = useState(State.init);
    const [selected, setSelected] = useState(null);
    const { callHubAsync, theme } = props;

    useEffect(() => {
        let cancelled = false;
        let onError = (error) => {
            if (!cancelled) {
                console.error(error);
                setState(State.error);
            }
        }
        if (state === State.sending) {
            async function sendAsync() {
                try {
                    const result = await callHubAsync("addAnswer", { value: selected });
                    if (result) {
                        await new Promise(r => setTimeout(r, outDuration + delayBetweenLikes));

                        if (cancelled) return;
                        setState(State.ready);
                    }
                    else {
                        onError("failed to send answer");
                    }
                }
                catch (error) {
                    onError(error);
                }
            }

            sendAsync();

            return () => cancelled = true;

        } else {
            return undefined;
        }
    }, [state, callHubAsync, setState])

    function sendMessage(event) {
        if (selected === event) {
            setSelected(null);
        } else {
            setSelected(event);
        }
        setState(State.sending);
    }

    return (
        <div>
            <div className="trueFalseWrapper" style={{ marginBottom: '1.5em' }}>
                <svg
                    className="TrueFalseSVG"
                    viewBox="0 0 1024 1024"
                    preserveAspectRatio="xMidYMid meet"
                    onClick={() => sendMessage(true)}
                    fill={theme.lightColor}
                    stroke={theme.lightColor}
                >
                    <path d={selected === true ? pathCheckSelected : pathCheckUnselected} />
                </svg>

                <svg
                    className="TrueFalseSVG"
                    viewBox="0 0 1024 1024"
                    preserveAspectRatio="xMidYMid meet"
                    onClick={() => sendMessage(false)}
                    fill={theme.lightColor}
                    stroke={theme.lightColor}
                >
                    <path d={selected === false ? pathCrossSelected : pathCrossUnselected} />
                </svg>
            </div>
            <p><em>{selected === null
                ? '\u00A0' // Non-breaking space
                : selected
                    ? t('true')
                    : t('false')}
            </em></p>
        </div>
    );
}
import React, { useContext } from 'react';
import { AngleLeftIcon, AngleRightIcon } from 'react-line-awesome'
import { ThemeContext, defaultTheme } from '../../../Theme';
import "./Presenter.css"

export function NavigationBar(props) {
    const { currentPageIndex, changePage, pageCount } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    if (pageCount > 1) {
        return (<div className='nav-bar'>
            <button
                disabled={(currentPageIndex <= 0) ? true : false}
                style={{ backgroundColor: theme.darkColor }}
                onClick={() => changePage(currentPageIndex - 1)}>
                <AngleLeftIcon className='icon' />
            </button>
            <button
                disabled={(currentPageIndex >= pageCount - 1) ? true : false}
                style={{ backgroundColor: theme.darkColor }}
                onClick={() => changePage(currentPageIndex + 1)}>
                <AngleRightIcon className='icon'/>
            </button>
        </div>);
    }
    return <></>;
}
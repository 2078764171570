// Forked from https://github.com/embiem/react-canvas-draw/


import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { LazyBrush } from "lazy-brush";
// import { Catenary } from "catenary-curve";
import { v4 as uuidv4 } from 'uuid';

import ResizeObserver from "resize-observer-polyfill";

function midPointBtw(p1, p2) {
    return {
        x: p1.x + (p2.x - p1.x) / 2,
        y: p1.y + (p2.y - p1.y) / 2
    };
}

const canvasStyle = {
    display: "block",
    position: "absolute"
};

const canvasTypes = [
    {
        name: "interface",
        zIndex: 15
    },
    {
        name: "drawing",
        zIndex: 11
    },
    {
        name: "temp",
        zIndex: 12
    }
];

const dimensionPropType = PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
]);

const sizePropType = PropTypes.shape({
    width: dimensionPropType,
    height: dimensionPropType
});

const penPropType = PropTypes.shape({
    size: PropTypes.number,
    color: PropTypes.string,
    eraser: PropTypes.bool
});

export class DrawingSurface extends PureComponent {
    static propTypes = {
        pen: penPropType,
        canvasSize: sizePropType,
        scaleFactor: PropTypes.number,
        hideInterface: PropTypes.bool,

        penSizeRatio: PropTypes.number,
        isStylus: PropTypes.bool,
        canDraw: PropTypes.bool,

        annotations: PropTypes.array.isRequired,
        addLines: PropTypes.func.isRequired,
        removeLines: PropTypes.func.isRequired,

        onChange: PropTypes.func,
        loadTimeOffset: PropTypes.number,
        lazyRadius: PropTypes.number,
        // catenaryColor: PropTypes.string,
        backgroundColor: PropTypes.string,
        disabled: PropTypes.bool,
        saveData: PropTypes.string,
        immediateLoading: PropTypes.bool
    };

    static defaultProps = {
        pen: {
            size: 5,
            color: '#F00',
            eraser: false
        },
        canvasSize: { width: 400, height: 400 },
        scaleFactor: 1,
        hideInterface: false,

        penSizeRatio: 1,
        isStylus: false,
        canDraw: false,

        annotations: [],
        addLines: () => { },
        removeLines: () => { },

        onChange: null,
        loadTimeOffset: 5,
        lazyRadius: 1,
        // catenaryColor: "#0a0302",
        backgroundColor: "transparent",
        disabled: false,
        saveData: "",
        immediateLoading: false,
    };

    constructor(props) {
        super(props);

        this.canvas = {};
        this.ctx = {};

        // this.catenary = new Catenary();

        this.points = [];
        this.penID = -1;

        this.mouseHasMoved = true;
        this.penChanged = true;
        this.isDrawing = false;
        this.isPressing = false;

        this.containerRef = React.createRef();
    }

    componentDidMount() {
        this.lazy = new LazyBrush({
            radius: this.props.lazyRadius * window.devicePixelRatio,
            enabled: true,
            initialPoint: {
                x: window.innerWidth / 2,
                y: window.innerHeight / 2
            }
        });
        this.chainLength = this.props.lazyRadius * window.devicePixelRatio;

        this.canvasObserver = new ResizeObserver((entries, observer) =>
            this.handleCanvasResize(entries, observer)
        );

        if (this.containerRef && this.containerRef.current) {
            this.canvasObserver.observe(this.containerRef.current);

            this.containerRef.current.onpointerover = (e) => {
                if (this.props.isStylus && e.pointerType === "pen") {
                    this.penID = e.pointerID;
                }
            }

            this.containerRef.current.onpointerdown = (e) => {
                ////console.log("down : isPrimary ? " + e.isPrimary + " canDraw ? " + this.props.canDraw +
                //    " pointerType ? " + e.pointerType);
                if (!e.isPrimary) {
                    this.isDrawing = false;
                    this.isPressing = false;
                    this.saveLine();
                }
                if (this.isDrawable(e)) {
                    //console.log("start draw");
                    this.handleDrawStart(e);
                }
            };
            this.containerRef.current.onpointermove = (e) => {
                if (this.isDrawable(e)) {
                    if (this.isPressing) {
                        this.handleDrawMove(e);
                    } else {
                        if (e.pressure !== 0) {
                            this.handleDrawStart(e);
                        }
                    }
                }
            };
            this.containerRef.current.onpointerup = (e) => {
                if (this.isDrawable(e)) {
                    this.handleDrawEnd(e);
                }
            };
            this.containerRef.current.onpointerout = (e) => {
                if (this.isDrawable(e)) {
                    this.handleDrawEnd(e);
                }
            };
            this.containerRef.current.onpointercancel = (e) => {
                if (this.isDrawable(e)) {
                    this.handleDrawEnd(e);
                }
            };
            this.containerRef.current.onpointerleave = (e) => {
                if (this.isDrawable(e)) {
                    this.handleDrawEnd(e);
                }
            };
        }

        this.loop();

        window.setTimeout(() => {
            const initX = window.innerWidth / 2;
            const initY = window.innerHeight / 2;
            this.lazy.update(
                { x: initX - this.chainLength / 4, y: initY },
                { both: true }
            );
            this.lazy.update(
                { x: initX + this.chainLength / 4, y: initY },
                { both: false }
            );
            this.mouseHasMoved = true;
            this.penChanged = true;
            this.clear();

            // Load saveData from prop if it exists
            if (this.props.saveData) {
                this.loadSaveData(this.props.saveData);
            }
        }, 100);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.lazyRadius !== this.props.lazyRadius) {
            // Set new lazyRadius values
            this.chainLength = this.props.lazyRadius * window.devicePixelRatio;
            this.lazy.setRadius(this.props.lazyRadius * window.devicePixelRatio);
        }

        let prevLines = prevProps.annotations;  
        let newLines = this.props.annotations;

        if ( prevLines.length !== newLines.length || // added or removed line(s)
            (prevLines.length === 0 || newLines.length === 0 || prevLines[0].id !== newLines[0].id)) // whole array changed
        {
            this.redraw();
        }

        if (JSON.stringify(prevProps.pen) !== JSON.stringify(this.props.pen)) {
            // Signal this.loop function that values changed
            this.penChanged = true;
        }

        if (prevProps.canDraw !== this.props.canDraw) {
            this.saveLine();
        }

    }

    componentWillUnmount = () => {
        if (this.containerRef && this.containerRef.current)
            this.canvasObserver.unobserve(this.containerRef.current);
    };

    isDrawable = (e) => {
        return (!this.props.isStylus || e.pointerType === "pen" || e.pointerID === this.penID) && this.props.canDraw && e.isPrimary;
    }

    undo = () => {
        if (this.props.annotations.length > 0) {
            this.props.removeLines(this.props.annotations[this.props.annotations.length - 1]);
        }
    };

    redraw = () => {
        this.ctx.drawing.clearRect(0.5, 0.5, this.canvas.drawing.width, this.canvas.drawing.height);

        this.props.annotations.forEach(line => {
            this.drawPoints({
                points: line.points.map(p => this.unNormalize(p)),
                brushColor: line.brushColor,
                brushRadius: (line.brushRadius * this.props.scaleFactor) / 2.0,
                tmp: false
            });
        });
    };

    handleDrawStart = e => {
        // e.preventDefault();
        // e.stopPropagation();

        // Start drawing
        this.isPressing = true;

        const { x, y } = this.getPointerPos(e);

        if (e.touches && e.touches.length > 0) {
            // on touch, set catenary position to touch pos
            this.lazy.update({ x, y }, { both: true });
        }
        // Reset points array
        this.points.length = 0;

        // Ensure the initial down position gets added to our line
        this.handlePointerMove(x, y);
    };

    handleDrawMove = e => {
        // e.preventDefault();
        // e.stopPropagation();

        const { x, y } = this.getPointerPos(e);
        this.handlePointerMove(x, y);
    };

    handleDrawEnd = e => {
        // e.preventDefault();
        // e.stopPropagation();

        // Draw to this end pos
        this.handleDrawMove(e);

        // Stop drawing & save the drawn line
        this.isDrawing = false;
        this.isPressing = false;
        this.saveLine();
    };

    handleCanvasResize = (entries, observer) => {
        for (const entry of entries) {
            const { width, height } = entry.contentRect;
            this.setCanvasSize(this.canvas.interface, width, height);
            this.setCanvasSize(this.canvas.drawing, width, height);
            this.setCanvasSize(this.canvas.temp, width, height);

            this.loop({ once: true });
        }
        this.redraw();
    };

    setCanvasSize = (canvas, width, height) => {
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = width;
        canvas.style.height = height;
    };

    getPointerPos = e => {
        const rect = this.canvas.interface.getBoundingClientRect();

        // use cursor pos as default
        let clientX = e.clientX;
        let clientY = e.clientY;

        // use first touch if available
        if (e.changedTouches && e.changedTouches.length > 0) {
            clientX = e.changedTouches[0].clientX;
            clientY = e.changedTouches[0].clientY;
        }

        // return mouse/touch position inside canvas
        return {
            x: (clientX - rect.left) / this.props.scaleFactor,
            y: (clientY - rect.top) / this.props.scaleFactor
        };
    };

    handlePointerMove = (x, y) => {
        if (this.props.disabled) return;

        this.lazy.update({ x, y });
        const isDisabled = !this.lazy.isEnabled();

        if (
            (this.isPressing && !this.isDrawing) ||
            (isDisabled && this.isPressing)
        ) {
            // Start drawing and add point
            this.isDrawing = true;

            if (!this.props.pen.eraser)
                this.points.push({ x: this.lazy.brush.x + 0.5, y: this.lazy.brush.y + 0.5 });
        }

        if (this.isDrawing) {
            if (this.props.pen.eraser) {
                const { x: nX, y: nY } = this.normalize({ x, y });
                const { x: nSize, y: _ } = this.normalize({ x: this.props.pen.size, y: 0 });

                var removedLines = this.props.annotations
                    .filter(l => l.points.some(nP => {
                        let dX = Math.abs(nP.x - nX);
                        let dY = Math.abs(nP.y - nY);

                        return dX < nSize && dY < nSize;
                    }));

                if (removedLines.length > 0) {
                    this.props.removeLines(removedLines);
                }

            } else {
                // Add new point
                this.points.push({ x: this.lazy.brush.x + 0.5, y: this.lazy.brush.y + 0.5 });

                // Draw current points
                this.drawPoints({
                    points: this.points,
                    brushColor: this.props.pen.color,
                    brushRadius: this.props.pen.size
                });
            }
        }

        this.mouseHasMoved = true;
    };

    drawPoints = ({ points, brushColor, brushRadius, tmp = true }) => {
        const destination = tmp ?
            this.ctx.temp :
            this.ctx.drawing;

        destination.lineJoin = "round";
        destination.lineCap = "round";
        destination.strokeStyle = brushColor;

        if (tmp) {
            destination.clearRect(
                0.5,
                0.5,
                destination.canvas.width,
                destination.canvas.height
            );
        }

        destination.lineWidth = (brushRadius * 2.0) / this.props.scaleFactor;

        let p1 = points[0];
        let p2 = points[1];

        destination.moveTo(p2.x, p2.y);
        destination.beginPath();

        for (var i = 1, len = points.length; i < len; i++) {
            // we pick the point between pi+1 & pi+2 as the
            // end point and p1 as our control point
            var midPoint = midPointBtw(p1, p2);
            destination.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y);
            p1 = points[i];
            p2 = points[i + 1];
        }
        // Draw last line as a straight line while
        // we wait for the next point to be able to calculate
        // the bezier control point
        destination.lineTo(p1.x, p1.y);
        destination.stroke();
    };

    saveLine = ({ brushColor, brushRadius } = {}) => {
        if (this.points.length < 3) return;

        const width = this.canvas.temp.width;
        const height = this.canvas.temp.height;
        // Save as new line
        this.props.addLines([{
            id: uuidv4(),
            points: this.points.map(p => this.normalize(p)),
            brushColor: brushColor || this.props.pen.color, 
            brushRadius: brushRadius || (this.props.pen.size * 2 / this.props.scaleFactor), 
            width: width,
            height: height
        }]);

        // Reset points array
        this.points.length = 0;

        // Copy the line to the drawing canvas
        this.ctx.drawing.drawImage(this.canvas.temp, 0.5, 0.5, width, height);

        // Clear the temporary line-drawing canvas
        this.ctx.temp.clearRect(0.5, 0.5, width, height);
    };

    clear = () => {
        this.props.removeLines(this.props.annotations);
    };

    loop = ({ once = false } = {}) => {
        if (this.mouseHasMoved || this.penChanged) {
            const pointer = this.lazy.getPointerCoordinates();
            const brush = this.lazy.getBrushCoordinates();

            this.drawInterface(this.ctx.interface, pointer, brush);
            this.mouseHasMoved = false;
            this.penChanged = false;
        }

        if (!once) {
            window.requestAnimationFrame(() => {
                this.loop();
            });
        }
    };


    drawInterface = (ctx, pointer, brush) => {
        if (this.props.hideInterface) {
            if (!this.interfaceCleared) {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                this.interfaceCleared = true;
            }
            return;
        }

        this.interfaceCleared = false;

        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Draw brush preview
        if (this.props.pen.eraser) {
            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.strokeStyle = "white";
            ctx.arc(brush.x, brush.y, (this.props.pen.size * 2) / this.props.scaleFactor, 0, Math.PI * 2, true);
            ctx.stroke();

            ctx.beginPath();
            ctx.lineWidth = 1;
            ctx.strokeStyle = "black";
            ctx.arc(brush.x, brush.y, ((this.props.pen.size + 2) * 2) / this.props.scaleFactor, 0, Math.PI * 2, true);
            ctx.stroke();

        } /* else {
            ctx.beginPath();
            ctx.fillStyle = this.props.pen.color;
            ctx.arc(brush.x, brush.y, (this.props.pen.size * 2) / this.props.scaleFactor, 0, Math.PI * 2, true);
            ctx.fill();
        } */

        // Draw mouse point (the one directly at the cursor)
        // ctx.beginPath();
        // ctx.fillStyle = this.props.catenaryColor;
        // ctx.arc(pointer.x, pointer.y, 4, 0, Math.PI * 2, true);
        // ctx.fill();

        // Draw catenary
        // if (this.lazy.isEnabled()) {
        //   ctx.beginPath();
        //   ctx.lineWidth = 2;
        //   ctx.lineCap = "round";
        //   ctx.setLineDash([2, 4]);
        //   ctx.strokeStyle = this.props.catenaryColor;
        //   this.catenary.drawToCanvas(
        //     this.ctx.interface,
        //     brush,
        //     pointer,
        //     this.chainLength
        //   );
        //   ctx.stroke();
        // }

        // Draw brush point (the one in the middle of the brush preview)
        // ctx.beginPath();
        // ctx.fillStyle = this.props.catenaryColor;
        // ctx.arc(brush.x, brush.y, 2, 0, Math.PI * 2, true);
        // ctx.fill();
    };


    // Transform screen position inside a [0, 1]x[0, 1] normalized value
    // The annotation zone is extended to a square so as to not deform cursor
    normalize = ({ x, y }) => {
        let greatestSideLength = Math.max(this.props.canvasSize.width, this.props.canvasSize.height);

        return {
            x: x / greatestSideLength,
            y: y / greatestSideLength
        };
    };

    unNormalize = ({ x, y }) => {
        let greatestSideLength = Math.max(this.props.canvasSize.width, this.props.canvasSize.height);

        return {
            x: x * greatestSideLength,
            y: y * greatestSideLength
        };
    };

    render() {
        return (
            <div
                ref={this.containerRef}
                className={this.props.className}
                style={{
                    display: "block",
                    background: this.props.backgroundColor,
                    touchAction: "none",
                    width: this.props.canvasSize.width,
                    height: this.props.canvasSize.height,
                    margin: 'auto',
                    ...this.props.style
                }}
            >
                {canvasTypes.map(({ name, zIndex }) => {
                    return (
                        <canvas
                            key={name}
                            ref={canvas => {
                                if (canvas) {
                                    this.canvas[name] = canvas;
                                    this.ctx[name] = canvas.getContext("2d");
                                }
                            }}
                            style={{ ...canvasStyle, zIndex }}
                        />
                    );
                })}
            </div>
        );
    }
}
import React, { useState, useEffect } from "react";
import { useTranslate } from "react-translate";
import './Presenter.css'

export function NoDocument(props) {
    const t = useTranslate("Presenter");
    const { presenters } = props;
    const [currentPresenters, setCurrentPresenters] = useState(presenters);

    useEffect(() => {
        if (props.setPresentersChangedCallback) {
            props.setPresentersChangedCallback(onPresentersChanged);
        }

        return () => {
            if (props.setPresentersChangedCallback) {
                props.setPresentersChangedCallback(null);
            }
        }
    }, [props.setPresentersChangedCallback]);

    function onPresentersChanged(eventName, args, time) {
        var presentersChangedData = JSON.parse(args);
        setCurrentPresenters(presentersChangedData.presenters);
    }

    function NoDocument() {
        if (!currentPresenters || currentPresenters.length === 0) {
            return <p>{t("instructions")}</p>;
        } else if (currentPresenters.length === 1) {
            return <p><b>{currentPresenters[0].presenterName}</b> {t("presentation.otherpresenter")}</p>;
        } else {
            if (currentPresenters.length === 2) {
                return <p><b>{currentPresenters.map(p => p.presenterName).join(t("separator"))}</b> {t("presentation.otherpresenters")}</p>;
            }
            else {
                return <p><b>{currentPresenters.slice(0, currentPresenters.length - 1).map(p => `${p.presenterName}`).join(", ")}</b> et <b>{currentPresenters[currentPresenters.length - 1].presenterName}</b> {t("presentation.otherpresenters")}</p>;
            }
        }
    }

    return (
        <>
            <NoDocument />
        </>
    );
}

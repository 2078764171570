import React, { useState, useEffect } from 'react';
import * as State from '../State';
import { useTranslate } from 'react-translate';
import './VoteActivity.css';


const inDuration = 500;
const delayBetweenVotes = 10;
const outDuration = 10;
let initialState = true

export function VoteActivity(props) {
    const common = useTranslate('Common');
    const [state, setState] = useState(State.init);
    const [vote, setVote] = useState('');
    let [items, setItems] = useState([])
    const { callHubAsync, subscribeToEvent, theme } = props;

    useEffect(() => {
        let cancelled = false;
        let onError = (error) => {
            initialState = true
            if (!cancelled) {
                console.error(error);
                setState(State.error);
            }
        }
        if (state === State.init) {
            initialState = true
            new Promise(r => setTimeout(r, inDuration))
                .then(() => {
                    if (!cancelled) {
                        setState(State.ready);
                    }
                })
                .catch(onError);

            return () => cancelled = true;
        }
        if (state === State.ready) {           
            async function displayImages(choicesArray) {
                items = await displayImagesVote(choicesArray, false)
                setItems(items)
            }
            function createElementsArray(choices) {
                let elementsArray = [];
                if (choices !== undefined) {
                    for (let i = 0; i < choices.length; i++) {
                        elementsArray.push({
                            'key': choices[i].id,
                            'value': choices[i].uri
                        });
                    }
                }
                return elementsArray
            }

            if (initialState) {
                items = createElementsArray(props.choicesArgs)
                displayImages(items);
                initialState = false
            }
        }
        if (state === State.sending) {
            async function sendAsync() {
                try {
                    const result = await callHubAsync("addVote", { ChoiceId: vote });
                    if (result) {
                        await new Promise(r => setTimeout(r, outDuration + delayBetweenVotes));

                        if (cancelled) return;

                        setVote(undefined);
                        setState(State.ready);
                    }
                    else {
                        onError("failed to send vote");
                    }
                }
                catch (error) {
                    onError(error);
                }
            }

            sendAsync();

            return () => cancelled = true;

        } else {
            return undefined;
        }
    }, [state, callHubAsync, setVote, setState, setItems, subscribeToEvent])

    async function displayImagesVote(choicesArray, fromEvent) {
        
        let itemList = []
        
        for (const [index, value] of choicesArray.entries()) {
            //requete pour get le fichier ?
            let file = null
            if (fromEvent)
            {
                file = await getFileAsync(value.uri);
            }
            else
            {
                file = await getFileAsync(value.value);
            }
            if (file != null) {
                var binaryData = [];
                if (value.key === undefined) {
                    binaryData.push(file);
                    let urlImage = window.URL.createObjectURL(new Blob(binaryData, { type: "image/png" }))
                    itemList.push(
                        <div key={value.id} className="voteContainer" onClick={() => displayNumber(value.id + "_checkButton")}>
                            <img className="imageVote" id={value.id} alt="PossibleChoice" src={urlImage}></img>
                            <svg className="checked" id={value.id + "_checkButton"} alt="PossibleChoice" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="512" cy="512" r="512" fill={theme.lightAccentColor} />
                                <path d="M128,544C128,535.333 131.167,527.833 137.5,521.5C143.833,515.167 151.333,512 160,512C168.667,512 176.167,515.167 182.5,521.5L384,722.5L841.5,265.5C847.833,259.167 855.333,256 864,256C872.667,256 880.167,259.167 886.5,265.5C892.833,271.833 896,279.333 896,288C896,296.667 892.833,304.167 886.5,310.5L406.5,790.5C400.167,796.833 392.667,800 384,800C375.333,800 367.833,796.833 361.5,790.5L137.5,566.5C131.167,560.167 128,552.667 128,544Z" fill="#FFFFFF"
                                    transform="scale(0.8) translate(130,180)">
                                </path>
                            </svg>
                        </div>
                    )
                } else {
                    binaryData.push(file);
                    let urlImage = window.URL.createObjectURL(new Blob(binaryData, { type: "image/png" }))
                    itemList.push(
                        <div key={value.key} className="voteContainer" onClick={() => displayNumber(value.key + "_checkButton")}>
                            <img className="imageVote" id={value.key} alt="PossibleChoice" src={urlImage}></img>
                            <svg className="checked" id={value.key + "_checkButton"} alt="PossibleChoice" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="512" cy="512" r="512" fill={theme.lightAccentColor} />
                                <path d="M128,544C128,535.333 131.167,527.833 137.5,521.5C143.833,515.167 151.333,512 160,512C168.667,512 176.167,515.167 182.5,521.5L384,722.5L841.5,265.5C847.833,259.167 855.333,256 864,256C872.667,256 880.167,259.167 886.5,265.5C892.833,271.833 896,279.333 896,288C896,296.667 892.833,304.167 886.5,310.5L406.5,790.5C400.167,796.833 392.667,800 384,800C375.333,800 367.833,796.833 361.5,790.5L137.5,566.5C131.167,560.167 128,552.667 128,544Z" fill="#FFFFFF"
                                    transform="scale(0.8) translate(130,180)">
                                </path>
                            </svg>
                        </div>
                    )
                }

            }
            
        }
        if (itemList.length === 0) {
            itemList.push(
                <div key="noChoices">En attente de choix</ div>)
        }
        
        setItems(itemList)
        
        return itemList;
    }

    async function getFileAsync(fileUri) {
        return await fetch(fileUri, { method: 'GET' })
            .then((response) => response.blob())
            .then(data => { return data; });
    }

    function displayNumber(id) {
        //rajouter requete http + lien avec le services
        if (document.getElementsByClassName("clicked").length === 1 && document.getElementsByClassName("clicked")[0].id === id) {
            let boxes = document.querySelectorAll('.clicked');
            boxes.forEach(box => {
                box.classList.remove('clicked');
            });
        } else {
            let boxes = document.querySelectorAll('.clicked');
            boxes.forEach(box => {
                box.classList.remove('clicked');
            });
            document.getElementById(id).classList.add("clicked")
        }
        let selectedChoices = (Array.from(document.querySelectorAll('.clicked'))[0]?.id)?.replace("_checkButton", '')
        setVote(selectedChoices)
        sendMessage(selectedChoices)
    }

    function sendMessage(event) {
        setVote(event)
        setState(State.sending);
    }

    return (
        <form>
            <div className="wrapper">
                {items}
            </div>

            <p style={{ opacity: state === State.error ? 1 : 0 }}> {/* just hidden to keep layout */}
                {common('error.retry')}
            </p>
        </form>
    );
}

    
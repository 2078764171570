import React, { useMemo } from 'react';

//const Unknown = "M 68.054333,3.4179037e-6 14.163896,0.09751342 c -1.918829,0.0035 -3.472659,1.55969498 -3.473294,3.47852698 V 96.520641 c 1.8e-4,1.921217 1.557364,3.478749 3.478582,3.479358 h 71.658614 c 1.922396,0.0011 3.481417,-1.556961 3.481599,-3.479358 V 24.66573 c 3.78e-4,-0.818164 -0.287547,-1.610297 -0.813211,-2.237254 L 70.72576,1.2443374 C 70.063557,0.45433942 69.085162,-0.00138958 68.054333,3.4179037e-6 Z M 64.577262,6.9639724 64.556102,24.659646 c -0.0027,1.92445 1.55715,3.485674 3.481603,3.484611 H 82.349184 V 93.04204 H 17.64852 V 7.0492764 Z m 6.951134,6.0639116 6.842378,8.158451 h -6.851453 z"

export function FileIcon(props) {

    const icon = useMemo(() => {
        const ext = props.fileName.split('.').pop();

        switch (ext) {
            case 'jpg':
            case 'jpeg':
            case 'png':
                return "m 33 41 0 2 0 44 63 0 0 -46 -63 0 z m 4 4 39.203125 0 C 77.139139 53.305563 83.694437 59.860861 92 60.796875 L 92 83 l -4.986328 0 -14.5 -19.769531 -4.589844 7.652343 L 56.890625 56.171875 38.361328 82.642578 38.873047 83 37 83 37 45 Z M 80.199219 45 92 45 92 56.800781 C 85.862305 55.923473 81.076527 51.137695 80.199219 45 Z M 57.003906 62.986328 72.013672 83 42.994141 83 57.003906 62.986328 Z M 72.845703 70.449219 82.050781 83 l -5.892578 0 0.546875 -0.410156 -6.189453 -8.253906 2.330078 -3.886719 z";

            case 'pdf':
            case 'pptx':
                return "M 77.083985,55.083984 74.25586,57.914062 80.34375,64 74.257813,70.087891 77.085938,72.914062 86,63.998047 Z m -25.169923,0 L 43,63.998047 51.914062,72.914062 54.74414,70.085938 48.658203,64 54.74414,57.914062 Z M 33,41 V 87 H 96 V 41 Z m 4,4 H 92 V 83 H 37 Z";

            //case 'mov':
            //case 'mp4':
            //case 'avi':
            //    return "m 33,41 v 2 44 H 96 V 41 Z m 4,4 H 92 V 83 H 37 Z m 18.125,5.306641 v 13.71289 L 55.123,77.732422 58.123,76 66.998,70.876953 78.873,64.019531 67,57.164062 Z m 4,6.929687 5.873047,3.392578 5.875,3.390625 -5.875,3.392578 -5.875,3.390625 0.002,-6.783203 v -6.783203 z";

            default:
                return "m 41.5,33 v 62 h 46 V 50.945312 L 69.5,33 Z m 4,4 h 20 v 18 h 18 v 36 h -38 z m 24,1.65625 L 81.806641,50.962891 69.5,51 Z";
        }
    }, [props.fileName]);

    return (
        <svg
            className={["fileImage", props.className].join(' ')}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={props.size ?? 25}
            height={props.size ?? 25}
            style={{
                fill: '#ffffff',
                stroke: 'none',
                margin: '2px 0 0 0'
            }}
            viewBox="33 33 63 62">
            <path d={icon} />
        </svg >);
}

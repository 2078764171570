export function preloadImageAsync(source, onLoad) {
    return new Promise((resolve, reject) => {
        var tmpImage = new Image();
        tmpImage.onabort = e => reject(e);
        tmpImage.onerror = e => reject(e);
        tmpImage.onload = e => {
            if (onLoad)
                onLoad(e);
            resolve();
        }
        tmpImage.src = source;
    })
}
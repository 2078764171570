import React, { useState, useEffect, useRef, useMemo } from 'react';
import { SendButton } from '../SendButton';
import * as State from '../State';
import { useTranslate } from 'react-translate';
import { AutoResizeTextArea } from './AutoResizeTextArea';

import './NoteActivity.css';

const Yellow = { h: 50, s: 98, l: 57 } //#FDD924
const Orange = { h: 35, s: 100, l: 56 } //#FFA21E
const Green = { h: 85, s: 75, l: 51 } //#93E024
const Blue = { h: 180.5, s: 57, l: 58 } //#59D0D1
const Pink = { h: 349, s: 96, l: 78 } //#FD90A4
const NoteColors = [Yellow, Orange, Green, Blue, Pink];

const inDuration = 500;
const delayBetweenNotes = 200;
const outDuration = 700;

function hsla(color, alpha) {
    return `hsla(${color.h}, ${color.s}%, ${color.l}%, ${alpha})`;
}

export function NoteActivity(props) {
    const common = useTranslate('Common');
    const [text, setText] = useState('');
    const [state, setState] = useState(State.init);
    const textAreaRef = useRef(null);

    const { callHubAsync, colorSeed } = props;

    useEffect(() => {
        let cancelled = false;
        let onError = (error) => {
            if (!cancelled) {
                console.error(error);
                setState(State.error);
            }
        }
        if (state === State.init) {
            new Promise(r => setTimeout(r, inDuration))
                .then(() => {
                    if (!cancelled) {
                        setState(State.ready);
                        textAreaRef.current.focus();
                        ensureSubmitVisible();
                    }
                })
                .catch(onError);

            return () => cancelled = true;
        }
        if (state === State.sending) {
            async function sendAsync() {
                try {
                    const result = await callHubAsync("addNote", { text: text });
                    if (result) {
                        await new Promise(r => setTimeout(r, outDuration + delayBetweenNotes));

                        if (cancelled) return;

                        setText('');
                        setState(State.init);
                    }
                    else {
                        onError("failed to send note");
                    }
                }
                catch (error) {
                    onError(error);
                }
            }

            sendAsync();

            return () => cancelled = true;

        } else {
            return undefined;
        }
    }, [state, callHubAsync, setText, setState, textAreaRef]) // 'text' intentionally omitted here

    useEffect(() => {
        let lastCharacterAscii = text.slice(-1).charCodeAt(0);
        var trimmedText = text.trim();
        if (trimmedText.length === 0) {
            setText('');
            return;
        }
        else if (lastCharacterAscii === 10) {
            sendMessageOnEnter()
        }
    }, [text])


    function sendMessage(state, event) {
        event.preventDefault();
        setState(State.sending);
    }

    function sendMessageOnEnter() {
        setState(State.sending);
    }

    function ensureSubmitVisible() {
        setTimeout(() => {
            try {
                var anchor = document.getElementById('retryMessage');
                if (anchor !== undefined) {
                    anchor.scrollIntoView({ behavior: "instant", block: "end", inline: "nearest" });
                }
            } catch { }            
        }, 300);
    }

    function giveFocus(event) {
        event.preventDefault();

        if (state === State.ready)
        {
            textAreaRef.current.focus();
            ensureSubmitVisible();
            
        }
    }

    // Note Color
    const noteColor = NoteColors[Math.min(NoteColors.length - 1, Math.floor(colorSeed * NoteColors.length))];
    const stickedColor = {
        h: Math.max(0, noteColor.h - 4),
        s: noteColor.s,
        l: Math.max(0, noteColor.l - 6)
    };

    const stickedStyle = {
        background: `linear-gradient(${hsla(stickedColor, 1)} 0%, ${hsla(stickedColor, 0.75)} 20%, ${hsla(stickedColor, 0)} 25%)`
    };

    const containerStyle = useMemo(() => {
        const background = `${hsla(noteColor, 1)}`;

        if (state === State.init) {
            return {
                background,
                borderRadius: '5%',
                animation: `zoomIn ${inDuration}ms forwards`
            }
        } else if (state === State.sending) {
            return {
                background,
                borderRadius: '5%',
                animation: `backOutTop ${outDuration}ms forwards`
            }
        }

        // writing and error
        return {
            background,
            opacity: 1,
            borderRadius: '5%',
            transform: 'none'
        }
    }, [state, noteColor]);

    return (
        <form>
            <div className="noteContainer" style={containerStyle}>
                <div className="stickedContainer" style={stickedStyle} onClick={giveFocus} tabIndex="0">
                    <AutoResizeTextArea
                        innerAreaRef={textAreaRef}
                        text={text}
                        setText={setText}
                        readOnly={state !== State.ready}
                    />
                </div>
            </div>

            <SendButton
                state={state}
                onClick={sendMessage}
                disabled={!text}
            />

            <p id="retryMessage" style={{ opacity: state === State.error ? 1 : 0 }}> {/* just hidden to keep layout */}
                {common('error.retry')}
            </p>
        </form>
    );
}
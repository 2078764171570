import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { ThemeContext, defaultTheme } from '../Theme';

export function ThemedButton(props) {
    const theme = useContext(ThemeContext) || defaultTheme;

    const { style, className, content, ...buttonProps } = props;

    const themedStyle =
    {
        ...style,
        backgroundColor: theme.baseColor,
        borderColor: theme.darkColor,
        color: 'white'
    };

    let classes = "themedButton";
    if (className)
        classes += " " + className;

    return (
        <Button {...buttonProps} type="submit" className={classes} style={themedStyle} >
            {content}
        </Button>
    );
}
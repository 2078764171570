import React, { useState, useContext, useEffect } from 'react';
import { PencilIcon, EraserIcon, PaletteIcon, HandIcon, StylusIcon } from './Icons'
import { ThemeContext, defaultTheme } from '../../../Theme';
import { types } from './Tools'
import './Tools.css';

export function ToolFloatingButton(props) {
    const [floatingButtonState, setFloatingButtonState] = useState("HandNotSelected");

    switch (floatingButtonState) {
        case "HandNotSelected":
            return (<HandNotSelected {...props} setFloatingButtonState={setFloatingButtonState} />)
        case "HandSelected":
            return (<HandSelected {...props} setFloatingButtonState={setFloatingButtonState} />)
        case "HandEraserSelected":
            return (<HandEraserSelected {...props} setFloatingButtonState={setFloatingButtonState} />)
        case "StylusNotSelected":
            return (<StylusNotSelected {...props} setFloatingButtonState={setFloatingButtonState} />)
        case "StylusSelected":
            return (<StylusSelected {...props} setFloatingButtonState={setFloatingButtonState} />)
        case "StylusEraserSelected":
            return (<StylusEraserSelected {...props} setFloatingButtonState={setFloatingButtonState} />)
        default:
            break;
    }
}

function HandNotSelected(props) {
    const { setToolType, setFloatingButtonState, surfaceRef } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    //When arriving in HandNotSelected, set the HAND tool
    useEffect(() => {
        setToolType(types.HAND);
    }, []);

    //deal with surface event
    useEffect(() => {
        if (surfaceRef && surfaceRef.current) {
            surfaceRef.current.onpointerover = (e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusSelected state
                    setFloatingButtonState("StylusSelected");
                } 
            }
        }
    }, [surfaceRef]);

    return (
        <button
            className="floating-button"
            style={{ backgroundColor: theme.darkColor }}
            onPointerDown={(e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusSelected state
                    setFloatingButtonState("StylusSelected");
                } else {
                    //Switch to HandSelected state
                    setFloatingButtonState("HandSelected");
                }
            }}>
            <HandIcon className='icon' isSelected='' />
        </button>
    )
}

function HandSelected(props) {
    const { paletteIsOpen, setPaletteIsOpen, tool, setToolType, setFloatingButtonState, surfaceRef } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    //When arriving in HandSelected, set the PEN tool
    useEffect(() => {
        setToolType(types.PEN);
    }, []);

    //When tool is Eraser, switch to HandEraserSelected state
    useEffect(() => {
        if ((tool["type"] === types.ERASER)) {
            setFloatingButtonState("HandEraserSelected");
        }
    }, [tool]);

    //deal with surface event
    useEffect(() => {
        if (surfaceRef && surfaceRef.current) {
            surfaceRef.current.onpointerover = (e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusSelected state
                    setFloatingButtonState("StylusSelected");
                } 
                if (!e.isPrimary) {
                    //More than one fingers detected (user want to zoom)
                    setFloatingButtonState("HandNotSelected");
                }
            }
        }

    }, [surfaceRef]);

    return (<>
        <button
            className="floating-button"
            style={{ backgroundColor: theme.baseColor }}
            onPointerDown={(e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusSelected state
                    setFloatingButtonState("StylusSelected");
                } else {
                    //Switch to HandNotSelected state
                    setFloatingButtonState("HandNotSelected");
                }
            }}>
            <HandIcon className='icon' isSelected={true} />
        </button>
        <PaletteButton {...{ paletteIsOpen, setPaletteIsOpen }} />
    </>)
}

function HandEraserSelected(props) {
    const { paletteIsOpen, setPaletteIsOpen, tool, setFloatingButtonState, surfaceRef, setStylusDetected } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    //When tool is Pen, switch to HandSelected state
    useEffect(() => {
        if ((tool["type"] === types.PEN)) {
            setFloatingButtonState("HandSelected");
        }
    }, [tool]);

    //deal with surface event
    useEffect(() => {
        if (surfaceRef && surfaceRef.current) {
            surfaceRef.current.onpointerover = (e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusEraserSelected state
                    setFloatingButtonState("StylusEraserSelected");
                } 
                if (!e.isPrimary) {
                    setFloatingButtonState("HandNotSelected");
                }
            }
        }
    }, [surfaceRef]);

    return (<>
        <button
            className="floating-button"
            style={{ backgroundColor: theme.baseColor }}
            onPointerDown={(e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusNotSelected state
                    setFloatingButtonState("StylusNotSelected");
                } else {
                    //Switch to HandNotSelected state
                    setFloatingButtonState("HandNotSelected");
                }
            }}>
            <EraserIcon className='icon' isSelected={true} />
        </button>
        <PaletteButton {...{ paletteIsOpen, setPaletteIsOpen }} />
    </>)
}

function StylusNotSelected(props) {
    const { setToolType, setFloatingButtonState, surfaceRef, setStylusDetected } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    //When arriving in StylusNotSelected, set the HAND tool
    useEffect(() => {
        setToolType(types.HAND);
        setStylusDetected(true);
    }, []);

    //deal with surface event
    useEffect(() => {
        if (surfaceRef && surfaceRef.current) {
            surfaceRef.current.onpointerover = (e) => {
                if (e.pointerType === "pen") {
                    //Stylus detected, switch to StylusSelected state
                    setFloatingButtonState("StylusSelected");
                } else {
                    //no need to switch state
                }
            }
        }
    }, [surfaceRef]);

    return (
        <button
            className="floating-button"
            style={{ backgroundColor: theme.darkColor }}
            onPointerDown={() => {
                //Switch to StylusSelected state
                setFloatingButtonState("StylusSelected");
            }}>
            <StylusIcon className='icon' isSelected='' />
        </button>
    )
}

function StylusSelected(props) {
    const { paletteIsOpen, setPaletteIsOpen, tool, setToolType, setFloatingButtonState, surfaceRef, setStylusDetected } = props;
    const theme = useContext(ThemeContext) || defaultTheme;
    var penId = -1;
    var stylus = false;

    //When arriving in StylusSelected, set the PEN tool
    useEffect(() => {
        setToolType(types.PEN);
        setStylusDetected(true);
    }, []);

    //When tool is Eraser, switch to StylusEraserSelected state
    useEffect(() => {
        if ((tool["type"] === types.ERASER)) {
            setFloatingButtonState("StylusEraserSelected");
        }
    }, [tool]);

    //deal with surface event
    useEffect(() => {
        if (surfaceRef && surfaceRef.current) {
            surfaceRef.current.onpointerover = (e) => {
                if (!paletteIsOpen && e.pointerType === "pen") {
                    penId = e.pointerId;
                }
            }
            surfaceRef.current.onpointerdown = (e) => {
                if (!paletteIsOpen && e.pointerType !== "pen" && e.pointerId !== penId && !stylus) {
                    setToolType(types.HAND);
                }
                if (!paletteIsOpen && (e.pointerType === "pen" || e.pointerId === penId)) {
                    setToolType(types.PEN);
                    stylus = true;
                }
            }
            surfaceRef.current.onpointerup = (e) => {
                if (!paletteIsOpen && (e.pointerType === "pen" || e.pointerId === penId)) {
                    stylus = false;
                } 
            }
        }
    }, [surfaceRef, paletteIsOpen]);

    return (<>
        <button
            className="floating-button"
            style={{ backgroundColor: theme.baseColor }}
            onPointerDown={() => {
                //switch to StylusNotSelected state
                setFloatingButtonState("StylusNotSelected");
            }}>
            <StylusIcon className='icon' isSelected={true} />
        </button>
        <PaletteButton onClick={() => setToolType(types.PEN)} {...{ paletteIsOpen, setPaletteIsOpen }} />
    </>)
}

function StylusEraserSelected(props) {
    const { paletteIsOpen, setPaletteIsOpen, tool, setToolType, setFloatingButtonState, surfaceRef } = props;
    const theme = useContext(ThemeContext) || defaultTheme;
    var penId = -1;
    var stylus = true;

    //When tool is Pen, switch to StylusSelected state
    useEffect(() => {
        if ((tool["type"] === types.PEN)) {
            setFloatingButtonState("StylusSelected");
        }
    }, [tool]);

    //deal with surface event
    useEffect(() => {
        if (surfaceRef && surfaceRef.current) {
            surfaceRef.current.onpointerover = (e) => {
                if (!paletteIsOpen && e.pointerType === "pen") {
                    penId = e.pointerId;
                }
            }
            surfaceRef.current.onpointerdown = (e) => {
                if (!paletteIsOpen && e.pointerType !== "pen" && !stylus) {
                    setToolType(types.HAND);
                }
                if (!paletteIsOpen && (e.pointerType === "pen" || e.pointerId === penId)) {
                    setToolType(types.ERASER);
                    stylus = true;
                }
            }
            surfaceRef.current.onpointerout = (e) => {
                if (!paletteIsOpen && e.pointerType === "pen") {
                    stylus = false;
                }
            }
        }
    }, [surfaceRef, paletteIsOpen]);

    return (<>
        <button
            className="floating-button"
            style={{ backgroundColor: theme.baseColor }}
            onPointerDown={() => {
                //Switch to StylusNotSelected state
                setFloatingButtonState("StylusNotSelected");
            }}>
            <EraserIcon className='icon' isSelected={true} />
        </button>
        <PaletteButton onClick={() => setToolType(types.ERASER)} {...{ paletteIsOpen, setPaletteIsOpen }} />
    </>)
}

function PaletteButton(props) {
    const { onClick, paletteIsOpen, setPaletteIsOpen } = props;
    const theme = useContext(ThemeContext) || defaultTheme;

    return (
        <button
            className="floating-button child"
            style={paletteIsOpen
                ? { display: 'none' }
                : { backgroundColor: theme.darkColor }}
            onClick={() => {
                if (onClick) {
                    onClick();
                }
                setPaletteIsOpen(true);
            }}>
            <div className='palette-icon-container'>
                <PaletteIcon size={25} className='icon' isSelected={paletteIsOpen} />
            </div>
        </button>
    )
}

///////////old version///////////////////

export function ToolButton({ paletteIsOpen, setPaletteIsOpen, tool, setTool }) {

    const [pencilIsOpen, setPencilIsOpen] = useState(false);
    const theme = useContext(ThemeContext) || defaultTheme;

    useEffect(() => {
        if (!pencilIsOpen) {
            setTool(previousTool => {
                var newTool = { ...previousTool };
                newTool["type"] = types.HAND;
                return newTool;
            });
        } else {
            setTool(previousTool => {
                var newTool = { ...previousTool };
                newTool["type"] = types.PEN;
                return newTool;
            });
        }
    }, [pencilIsOpen]);

    return (
        <div className='fab-container not-display-if-desktop'>
            <button
                className="floating-button"
                style={pencilIsOpen
                    ? { backgroundColor: theme.baseColor }
                    : { backgroundColor: theme.darkColor }}
                onClick={() => {
                    setPencilIsOpen(!pencilIsOpen);
                    setPaletteIsOpen(false);
                }}>
                {(tool["type"] === types.ERASER && pencilIsOpen)
                    ? <EraserIcon />
                    : <PencilIcon className='icon' isSelected={(tool["type"] === types.PEN)} color={tool["color"]} />
                }

            </button>
            {pencilIsOpen
                ? (<button
                    className="floating-button child"
                    style={paletteIsOpen
                        ? { display: 'none' }
                        : { backgroundColor: theme.darkColor }}
                    onClick={() => {
                        setPaletteIsOpen(true);
                    }}>
                    <div style={{ marginTop: '7px' }}>
                        <PaletteIcon size={20} className='icon' isSelected={paletteIsOpen} />
                    </div>
                </button>
                ) : (<></>)}

        </div>
    )
}
import React, { useState, useContext, useEffect, useRef } from 'react';
import { TimesIcon } from 'react-line-awesome'
import { PencilIcon, EraserIcon } from './Icons'
import { ThemeContext, defaultTheme } from '../../../Theme';
import { types, sizes, colors, Action, ActionColor } from './Tools'
import './Tools.css';

export function ToolPalette(props) {

    const theme = useContext(ThemeContext) || defaultTheme;
    const [isEraser, setIsEraser] = useState(false);
    const ref = useRef();
    const { tool, setTool, setPaletteIsOpen, colorsArgs, colorGridCol, colorGridRow } = props;

    const background = theme.darkColor;

    useClickOutside(ref, () => setPaletteIsOpen(false));

    useEffect(() => {
        if (tool["type"] === types.ERASER) {
            setIsEraser(true);
        } else {
            setIsEraser(false);
        }
    }, [tool["type"]]);

    return (
        <div ref={ref} className='tool-palette'
            style={{
                backgroundColor: background,
                boxShadow: '9px 9px 10px 10px ' + theme.darkDarkColor,
                gridTemplateColumns: colorGridCol + 'fr 1fr',
                gridTemplateRows: '0.4fr ' + colorGridRow + 'fr 1fr'
            }}>
            <button className='row1 col2 cross'
                onClick={() => { setPaletteIsOpen(false); }}>
                <TimesIcon className='icon' />
            </button>
            <div className='color-grid row2 col1'
                style={{
                    gridTemplateColumns: '1fr '.repeat(colorGridCol),
                    gridTemplateRows: '1fr '.repeat(colorGridRow)
                }}>
                {colorsArgs.map(elem => (
                    <ActionColor {...{ tool, setTool }}
                        disabled={isEraser}
                        key={elem.color}
                        className={"row" + elem.row + " col" + elem.col}
                        color={elem.color} background={background} />
                ))}
            </div>
            <div className='size-grid row2 col2' style={{ gridTemplateRows: '1fr '.repeat(colorGridRow) }}>
                <div className={"row1 col1 "}>
                    <Action {...{ tool, setTool }} target="size" value={sizes.SMALL} disabled={isEraser} >
                        <div className="size-circle" style={{ background: tool["color"], width: "10%", height: "10%" }} />
                    </Action>
                </div>
                <div className={"row2 col1 "} >
                    <Action {...{ tool, setTool }} target="size" value={sizes.MEDIUM} disabled={isEraser} >
                        <div className="size-circle" style={{ background: tool["color"], width: "30%", height: "30%" }} />
                    </Action>
                </div>
                <div className={"row3 col1 "}>
                    <Action {...{ tool, setTool }} target="size" value={sizes.LARGE} disabled={isEraser} >
                        <div className="size-circle" style={{ background: tool["color"], width: "50%", height: "50%" }} />
                    </Action>
                </div>
            </div>
            <div className='tool-grid row3 col1' style={{ gridTemplateColumns: '1fr '.repeat(colorGridCol) }}>
                <div className="row1 col1" >
                    <Action {...{ tool, setTool }} target="type" value={types.PEN}>
                        <PencilIcon isSelected={(tool["type"] === types.PEN) || (tool["type"] === types.HAND)} color={tool["color"]} />
                    </Action>
                </div >
                <div className="row1 col2" >
                    <Action {...{ tool, setTool }} target="type" value={types.ERASER}>
                        <EraserIcon isSelected={tool["type"] === types.ERASER} />
                    </Action>
                </div>
            </div>
        </div>
    )
};

const useClickOutside = (ref, onClick) => {
    useEffect(() => {
        if (!ref?.current) {
            return;
        }
        const handleClickOutside = (e) => {
            if (onClick && !ref.current.contains(e.target)) {
                onClick(e);
            }
        };
        document.addEventListener('pointerdown', handleClickOutside);
        return () => {
            document.removeEventListener('pointerdown', handleClickOutside);
        };
    }, [ref, onClick]);
};

////////////////OLD VERSION///////////////////
export function ToolPaletteOld(props) {

    const theme = useContext(ThemeContext) || defaultTheme;
    const [isEraser, setIsEraser] = useState("");
    const ref = useRef();
    const { tool, setTool, setPaletteIsOpen } = props;
    const background = theme.darkColor;

    useClickOutside(ref, () => setPaletteIsOpen(false));

    useEffect(() => {
        if (tool["type"] === types.ERASER) {
            setIsEraser("opacity");
        } else {
            setIsEraser("");
        }
    }, [tool["type"]]);

    return (
        <div ref={ref} className='tool-menu' style={{ backgroundColor: background, boxShadow: '9px 9px 10px 10px ' + theme.darkDarkColor }}>
            <button className='row1 col2 cross'
                onClick={() => { setPaletteIsOpen(false); }}>
                <TimesIcon className='icon' />
            </button>
            <div className='color-grid row2 col1'>
                <ActionColor {...{ tool, setTool }} className={"row1 col1 " + isEraser} color={colors["YELLOW"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row2 col1 " + isEraser} color={colors["WHITE"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row3 col1 " + isEraser} color={colors["TURQUOISE"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row1 col2 " + isEraser} color={colors["ORANGE"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row2 col2 " + isEraser} color={colors["BLACK"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row3 col2 " + isEraser} color={colors["BLUE"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row1 col3 " + isEraser} color={colors["RED"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row2 col3 " + isEraser} color={colors["EXCENSE"]} background={background} />
                <ActionColor {...{ tool, setTool }} className={"row3 col3 " + isEraser} color={colors["GREEN"]} background={background} />
            </div>
            <div className='size-grid row2 col2'>
                <div className={"row1 col1 " + isEraser}>
                    <Action {...{ tool, setTool }} target="size" value={sizes.SMALL} >
                        <div className="size-circle" style={{ background: tool["color"], width: "10%", height: "10%" }} />
                    </Action>
                </div>
                <div className={"row2 col1 " + isEraser} >
                    <Action {...{ tool, setTool }} target="size" value={sizes.MEDIUM} >
                        <div className="size-circle" style={{ background: tool["color"], width: "30%", height: "30%" }} />
                    </Action>
                </div>
                <div className={"row3 col1 " + isEraser}>
                    <Action {...{ tool, setTool }} target="size" value={sizes.LARGE} >
                        <div className="size-circle" style={{ background: tool["color"], width: "50%", height: "50%" }} />
                    </Action>
                </div>
            </div>
            <div className='tool-grid row3 col1'>
                <div className="row1 col1" >
                    <Action {...{ tool, setTool }} target="type" value={types.PEN}>
                        <PencilIcon isSelected={(tool["type"] === types.PEN) || (tool["type"] === types.HAND)} color={tool["color"]} />
                    </Action>
                </div >
                <div className="row1 col2" >
                    <Action {...{ tool, setTool }} target="type" value={types.ERASER}>
                        <EraserIcon isSelected={tool["type"] === types.ERASER} />
                    </Action>
                </div>
            </div>
            <div className={(tool["type"] === types.ERASER) ? "shown-when-eraser" : ""}></div>
        </div>
    )
};
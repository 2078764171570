import React, { useRef, useState, useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { DrawingSurface } from './DrawingSurface';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { preloadImageAsync } from '../../../utils/ImageLoader';
import { useTranslate } from "react-translate";
import { useSetTimeout } from "../../../utils/useSetTimeout"
import "./Presenter.css"


export function ZoomPanPitchDraw(props) {
    const t = useTranslate("Presenter");
    const containerRef = useRef();
    const [containerSize, setContainerSize] = useState(undefined);
    const [imgSize, setImgSize] = useState(undefined);
    const [finalSize, setFinalSize] = useState(undefined);
    const [isTimerRunning, setIsTimerRunning] = useSetTimeout(5000);
    //const [scaleFactor, setScaleFactor] = useState(undefined);

    // We need to set manually image's initial size
    // So we retrieve image size and container size to compute desired one

    useEffect(() => {
        setIsTimerRunning(true);
        preloadImageAsync(
            props.imageSource,
            e => {
                var size = {
                    width: e.target.naturalWidth || e.target.width,
                    height: e.target.naturalHeight || e.target.height
                };
                setImgSize(size);
            }
        ).catch(error => {
            props.setError(t("error.imageLoading"));
        });
        
    }, [props.imageSource]);


    useEffect(() => {
        if (!containerSize) {
            adaptContainerSize();
        }
    }, [containerRef.current, containerSize]);

    
    window.onresize = () => {
        props.setSurfaceHeight(window.innerHeight);
        adaptContainerSize();
    }


    useEffect(() => {
        if (containerSize && imgSize) {
            var imgRatio = imgSize.width / imgSize.height;
            var containerRatio = containerSize.width / containerSize.height;
            var width = 0;
            var height = 0;

            if (imgRatio > containerRatio) {
                width = containerSize.width;
                height = width / imgRatio;
                //setScaleFactor(imgSize.width / width);
            } else {
                height = containerSize.height;
                width = height * imgRatio;
                //setScaleFactor(imgSize.height / height);
            }   
            setFinalSize({ width, height });
            setIsTimerRunning(false);
        }
    }, [containerSize, imgSize]);

    function adaptContainerSize() {
        if (containerRef.current) {
            var size = {
                width: containerRef.current.clientWidth,
                height: containerRef.current.clientHeight
            };
            setContainerSize(size);
        }
    }

    return (<>
        <div className='zppd' ref={containerRef} style={{pointerEvents: props.pan ? 'all' : 'none'}}>
            {finalSize
                ? <ZoomPanPitchDrawContent {...props} imgSize={imgSize} 
                    finalSize={finalSize} containerSize={containerSize}/>
                : (<div className="loading">
                    <span>{t("presentation.pageLoading")}</span>
                    <Spinner color="light" className='spinner' />
                    {!isTimerRunning ? <span>{t("presentation.pageLoadingTooLong")}</span> : <></>}
                </div>)}
        </div>
    </>);
}


function ZoomPanPitchDrawContent(props) {

    const { imageSource, imgSize, finalSize, containerSize, pan, stylusDetected, ...drawingProps } = props;

    return (<TransformWrapper
        options={{ limitToBounds: true, disabled: !pan, maxScale: 4 }}
        pan={{ paddingSize: 0 }}
        wheel={{ step: 200 }} 
    >
        {({ scale }) => (
            <TransformComponent>
                <div style={{ display: 'grid' }}>
                    <img className='zppdc-img' src={imageSource} alt="" style={{
                        width: containerSize.width,
                        height: containerSize.height
                    }} />

                    <div className='zppdc-drawing'>
                        <DrawingSurface
                            canvasSize={finalSize}
                            scaleFactor={scale}
                            hideInterface={pan}
                            canDraw={!pan}
                            isStylus={stylusDetected}
                            {...drawingProps}
                        />
                    </div>
                </div>
            </TransformComponent>
        )}
    </TransformWrapper>);
}
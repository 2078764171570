import React from 'react';

export function FileImage(props) {
    return (
        <svg
            className="fileImage"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            style={{
                maxWidth: 100,
                maxHeight: 100,
                height: '10vh',
                fill: '#ffffff',
                stroke: 'none',
                margin: '3vh'
            }}
            viewBox="0 0 100 100">
            <path d="M 68.054333,3.4179037e-6 14.163896,0.09751342 c -1.918829,0.0035 -3.472659,1.55969498 -3.473294,3.47852698 V 96.520641 c 1.8e-4,1.921217 1.557364,3.478749 3.478582,3.479358 h 71.658614 c 1.922396,0.0011 3.481417,-1.556961 3.481599,-3.479358 V 24.66573 c 3.78e-4,-0.818164 -0.287547,-1.610297 -0.813211,-2.237254 L 70.72576,1.2443374 C 70.063557,0.45433942 69.085162,-0.00138958 68.054333,3.4179037e-6 Z M 64.577262,6.9639724 64.556102,24.659646 c -0.0027,1.92445 1.55715,3.485674 3.481603,3.484611 H 82.349184 V 93.04204 H 17.64852 V 7.0492764 Z m 6.951134,6.0639116 6.842378,8.158451 H 71.519321 Z M 49.830111,31.991325 c -1.920979,0.02876 -3.455165,1.608899 -3.42723,3.52989 v 27.636136 l -9.072107,-9.094754 c -0.65528,-0.675231 -1.556079,-1.05631 -2.496997,-1.05634 -3.124982,-0.0038 -4.670756,3.793777 -2.431307,5.973317 L 47.028816,73.63613 c 1.38263,1.980945 4.313613,1.984831 5.701493,0.0076 L 67.392145,58.984866 c 3.343537,-3.27933 -1.637348,-8.26404 -4.919245,-4.923024 l -9.112109,9.111194 V 35.523294 c 0.02873,-1.961521 -1.569165,-3.558886 -3.53068,-3.529928 z" />
        </svg >);
}
import React, { useState, useEffect, useRef, useCallback } from 'react';
import * as signalR from "@microsoft/signalr"

import { ThemeContext, defaultTheme } from '../Theme';
import { LoginForm } from './LoginForm';
import { Spinner } from 'reactstrap';
import { ThemedButton } from './ThemedButton';
import { useTranslate } from 'react-translate';
import { ActivityRenderer } from './ActivityRenderer';
import { Logo } from './Logo';
import NoSleep from 'nosleep.js';

import * as State from './ConnectionState';
import * as SessionState from './SessionState';
import '../custom.css'

const hexaChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];

const eventCache = [];

// returns a number between 0 and 1 depending on input string
function getSeed(input) {
    return Math.max(0, hexaChars.indexOf(input.charAt(0)) / hexaChars.length);
}

export function ActivityManager(props) {
    const common = useTranslate('Common');
    const t = useTranslate('ActivityManager');
    const [reconnect, setReconnect] = useState(false);
    const [userId, setUserId] = useState('');
    const [userName, setUsername] = useState(undefined);
    const [projectName, setProjectName] = useState('');
    const [serviceVersion, setServiceVersion] = useState('');
    const [activity, setActivity] = useState('');
    const [adminConnectionId, setAdminConnectionId] = useState('');
    const [activityArgs, setActivityArgs] = useState(undefined);
    const [sessionState, setSessionState] = useState(SessionState.CheckingSession);
    const [connectionState, setConnectionState] = useState(State.Loading);
    const [theme, setTheme] = useState(defaultTheme);
    const [customActivityName, setCustomActivityName] = useState('');
    const [countDown, setCountDown] = React.useState(0);
    const [runTimer, setRunTimer] = React.useState(false);
    const [currentActivity, setCurrentActivity] = useState(props.activity);
    const [nextActivity, setNextActivity] = useState(null);
    const [noSleep, setNoSleep] = useState(new NoSleep());
    const [wakeLockEnabled, setWakeLockEnabled] = useState(false);
    const cancelUploadRef = useRef(null);
    const customActivityMessageHandlerRef = useRef(null);
    var isDemo = '';
    var adminConnectionIds = '';
    var isDemoExpired = false;
    
    function clearFooterClassName() {
        if (footerRef && footerRef.current) {
            footerRef.current.classList = "";
        }
    }

    function addFooterClassName(name) {
        if (footerRef && footerRef.current) {
            footerRef.current.classList.add(name);
        }
    }

    function setFooterClassName(name) {
        clearFooterClassName();
        addFooterClassName(name);
    }

    const connectionRef = useRef();

    const {
        sessionId,
        footerRef
    } = props;

    const handleSetCancelUpload = (cancelFunc) => {
        cancelUploadRef.current = cancelFunc;
    }

    const handleCustomActivityMessageHandler = (callbackFunc) => {
        customActivityMessageHandlerRef.current = callbackFunc;
        if (callbackFunc) {
            if (eventCache.length > 0) {
                //console.log("verifying " + eventCache.length + " cached events");
                var now = Date.now();
                while (eventCache.length > 0) {
                    var element = eventCache.shift();
                    var time = element.time;
                    if (now - time > 3000) {
                        //console.log("ignoring outdated event");
                        continue;
                    }
                    else {
                        //console.log("flushing event " + element.name);
                        callbackFunc(element.name, element.data, element.time);
                    }
                }
            }

        }
    }

    // initialize SignalR connection
    useEffect(() => {
        async function signalRConnect() {
            try {
                setConnectionState(State.Loading);
                let address = `${process.env.REACT_APP_COMPANION_FUNCTIONS_URL}/api/v1/negotiate`;
                const requestOptions = {
                    method: 'POST',
                    credentials: 'include',
                    headers: {
                        'x-ms-signalr-user-role': 'client'
                    },
                    body: JSON.stringify({ SessionName: sessionId, UserName: userName })
                };

                const fetchableNegociate = await fetch(address, requestOptions);
                var connection;

                if (fetchableNegociate.status === 401) {
                    setSessionState(SessionState.NotLogged);
                    setConnectionState(State.Connected);
                    return;
                } else if (fetchableNegociate.status === 404) {
                    setSessionState(SessionState.UnkownSession);
                    setConnectionState(State.Connected);
                    return;
                }

                const fetchableNegociateCo = await fetchableNegociate.json();
                if (fetchableNegociateCo.sessionDuration !== undefined) {
                    isDemo = fetchableNegociateCo.sessionDuration
                }

                setUserId(fetchableNegociateCo.userId);
                connection = new signalR.HubConnectionBuilder()
                    .withUrl(fetchableNegociateCo.serviceUrl, {
                        accessTokenFactory: () => { return fetchableNegociateCo.accessToken }
                    })
                    .withAutomaticReconnect({
                        nextRetryDelayInMilliseconds: context => {
                            return 3000;
                        }
                    })
                    .build();

                //abonne à l'event sessionInfo
                //session

                connection.on('startActivity', infos =>
                {
                    //console.log(`[${ new Date().toISOString() }] startActivity, data : ${infos}`);
                    var data = JSON.parse(infos);
                    setSessionState(SessionState.LoggedIn);
                    setCustomActivityName(data.customActivityName)
                    setActivityArgs(data.args);
                    setActivity(data.activity);
                });

                connection.on('stopActivity', () => {
                    //console.log(`[${new Date().toISOString() }] stopActivity`);
                    setActivity(undefined);
                    setSessionState(SessionState.LoggedIn);
                    setCustomActivityName('')
                    setFooterClassName("logoContainer");
                });

                connection.on('sessionInfo', infos => {
                    //console.log(`[${new Date().toISOString() }] sessionInfo, data: ${infos}`);
                    var data = JSON.parse(infos);
                    setSessionState(SessionState.LoggedIn);
                    setCustomActivityName(data?.customActivityName)
                    setServiceVersion(data.serviceVersion)
                    setProjectName(data.name);
                    setTheme(data.theme);
                    document.body.style.background = `radial-gradient(circle at 80% 80%, ${data.theme.darkColor}, ${data.theme.darkDarkColor})`;
                    setAdminConnectionId(data.adminConnectionId);
                    adminConnectionIds = data.adminConnectionId;
                    if (data.backgroundUri) {
                        const backgroundElement = document.getElementById('customBackground');
                        backgroundElement.style.backgroundImage = `url(${data.backgroundUri})`;
                        backgroundElement.style.opacity = 1;
                    }
                    if (data.activity) {
                        setActivityArgs(data.args);
                        setActivity(data.activity);
                    }
                });

                connection.on('whoareyou', () => {
                    //console.log(`[${new Date().toISOString()}] whoareyou`);
                    setSessionState(SessionState.LoggedIn);
                    sendMyIdentityAsync();
                });

                connection.on('sessionUsersChanged', infos => {
                    //console.log(`[${new Date().toISOString()}] sessionUsersChanged, data: ${infos}`);
                    if (infos.oldUser !== null && infos.oldUser.isAdmin) {
                        if (isDemo !== '') {
                            setActivity(undefined);
                            setFooterClassName("logoContainer");
                            isDemoExpired = true;
                            connection.stop();                            
                        } else {
                            setActivity(undefined);
                            setSessionState(SessionState.SessionPaused);
                            setFooterClassName("logoContainer");
                            setRunTimer(true);
                            setCountDown(120);
                        }
                    }
                    if (infos.newUser !== null && infos.newUser.isAdmin) {
                        setActivity(undefined);
                        setSessionState(SessionState.LoggedIn);
                        setFooterClassName("logoContainer");
                        setAdminConnectionId(infos.newUser.connectionId);
                        adminConnectionIds = infos.newUser.connectionId;
                        setRunTimer(false);
                        sendMyIdentityAsync();
                    }
                });

                connection.on('startPresentation', data => onCustomActivityMessageHandler('startPresentation', data));
                connection.on('stopPresentation', data => onCustomActivityMessageHandler('stopPresentation', data));
                connection.on('presentersChanged', data => onCustomActivityMessageHandler('presentersChanged', data));
                connection.on('presenterPage', data => onCustomActivityMessageHandler('presenterPage', data));

                connection.onreconnecting(error => {
                    setConnectionState(State.Reconnecting);
                });
                connection.onreconnected(() => {
                    setConnectionState(State.Connected);
                });
                connection.onclose(error =>
                {
                    setServiceVersion('')
                    if (error !== undefined && error.message !== undefined && error.message !== '') {
                        if (error.message.includes('ALREADY_CONNECTED')) {
                            setConnectionState(State.AlreadyConnected);
                        }
                        else if (error.message.includes('ping timeout')) {
                            setConnectionState(State.TabDisconnected);
                        }
                    }
                    else {
                        if (isDemo != '' && isDemoExpired) {
                            setConnectionState(State.DemoConnectionEnded);
                        } else {
                            setConnectionState(State.Reconnecting);
                        }                        
                    }
                });

                connectionRef.current = connection;
                await connectionRef.current.start();
                setSessionState(SessionState.LoggedIn);
                setConnectionState(State.Connected);
            } catch (err) {
                setConnectionState(State.Error);
                console.error(err.toString());
            }
        }

        signalRConnect();

        return () => {
            const connection = connectionRef?.current;
            if (connection) {
                connection.off('startActivity');
                connection.off('stopActivity');
                connection.off('sessionInfo');
                connection.off('sessionUsersChanged');
                connection.off('whoareyou');
                connection.off('startPresentation');
                connection.off('stopPresentation');
                connection.off('presentersChanged');
                connection.off('presenterPage');
                connection.stop()
                    .then(() => {
                        if (isDemo !== '') {
                            setConnectionState(State.DemoConnectionEnded)
                        } else {
                            setConnectionState(State.Reconnecting)
                        }                            
                    })
                    .catch(err => {
                        setConnectionState(State.Error);
                        console.error(err.toString());
                    });
                }
            };
        },
        [reconnect]//, clearUserConnectInfo] // the function will be called at first render and each time the value of 'reconnect' is changed (no matter true or false !)
    );

    useEffect(() => {
        if (activity !== currentActivity) {
            setNextActivity(activity);
        }
    }, [activity]);

    useEffect(() => {
        if (nextActivity !== null) {
            if (cancelUploadRef.current) {
                cancelUploadRef.current();
                cancelUploadRef.current = null;  // réinitialiser la ref
                setCurrentActivity(nextActivity);
                setNextActivity(null);  // réinitialiser la prochaine activité
            } else {
                setCurrentActivity(nextActivity);
                setNextActivity(null);
            }
        }
    }, [nextActivity]);

    useEffect(() => {
        if (sessionState === SessionState.SessionPaused) {
            const timer =
                countDown > -1 && setInterval(() => setCountDown(countDown - 1), 1000);
            return () => clearInterval(timer);
        }
        
    }, [countDown]);

    useEffect(() => {
        if (connectionState == State.TabDisconnected) {
            setReconnect(v => !v);
        }
    }, [connectionState])

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            setRunTimer(false);
            setCountDown(0);

            return () => {
                const connection = connectionRef?.current;
                if (connection) {
                    connection.off('startActivity');
                    connection.off('stopActivity');
                    connection.off('sessionInfo');
                    connection.off('sessionUsersChanged');
                    connection.off('startPresentation');
                    connection.off('stopPresentation');
                    connection.off('presentersChanged');
                    connection.off('presenterPage');
                    connection.stop()
                        .then(() => {
                            setSessionState(SessionState.SessionExpired)
                            setConnectionState(State.AdminConnectionEnded)
                        })
                        .catch(err => {
                            setConnectionState(State.Error);
                            console.error(err.toString());
                        });
                }
            }
        }
    }, [countDown]);

    function onCustomActivityMessageHandler(eventName, eventData) {
        //console.log(`[${new Date().toISOString()}] ${eventName}, data : ${eventData}`);
        var time = Date.now();
        if (customActivityMessageHandlerRef.current) {
            customActivityMessageHandlerRef.current(eventName, eventData, time);
        }
        else {
            //console.log("No handler registered for event " + eventName);
            //console.log("caching event");
            eventCache.push({ name: eventName, data: eventData, time: time });       
        }
    }

    async function genericCallHubAsync(methodName, args) {
        return await connectionRef.current.invoke("BroadcastDataTo", adminConnectionId, methodName, JSON.stringify(args));
    }

    async function publicCallHubAsync(methodName, args) {
        return await genericCallHubAsync(methodName, {
            data: args,
            userId: userId
        });
    }

    async function sendMyIdentityAsync() {
        return await connectionRef.current.invoke("SendMyIdentity", adminConnectionIds);
    }

    async function getUploadUriAsync(contentType) {
        return await connectionRef.current.invoke("GetUploadUri", { contentType : contentType });
    }

    async function getDownloadUriAsync(fileId) {
        return await connectionRef.current.invoke("GetDownloadUri", { fileId : fileId });
    }

    function wakelockFunction() {
        if (!wakeLockEnabled) {
            noSleep.enable();
            setWakeLockEnabled(true);
        }
    }


    const joinSessionAsync = useCallback(async (username = undefined) => {
        try {
            setUsername(username)
            setReconnect(v => !v)
            wakelockFunction()
        } catch (err) {
            console.error(err.toString());
            setSessionState(SessionState.LoginFailed);
        }
    }, [sessionId]);
    


    function ReconnectButton() {
        return <ThemedButton content={common('retry')} onClick={e => setReconnect(v => !v)} />;
    }

    function CheckAgainButton() {
        return <ThemedButton content={common('checkAgain')} onClick={e =>
            {
                setReconnect(v => !v)
                wakelockFunction()
            }
        } />;
    }

    function WakeLockButton() {
        return <ThemedButton content={common('join')} onClick={e => wakelockFunction()} />;
    }

    function RenderContent() {
        if (connectionState === State.Error) {
            return <>
                <p>{common('error')}</p>
                <ReconnectButton />
                <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
            </>;
        } else if (connectionState === State.Loading) {
            return <>
                <Spinner color="light" className='spinner' />
                <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
                {serviceVersion !== '' &&
                    <p className="versionNumber">Service: {serviceVersion}</p>
                }

            </>;
        } else if (connectionState === State.Connected) {
            
            switch (sessionState) {
                case SessionState.CheckingSession:
                    return <>
                        <Spinner color="light" className='spinner' />
                        <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
                    </>;
                case SessionState.UnkownSession:
                    return <>
                        <p>{t('session.unknown')}</p>
                        <CheckAgainButton />
                        <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
                    </>;
                case SessionState.LoggedIn: {
                    if (!wakeLockEnabled) {
                        return <>
                            <Logo maxSize={150} />
                            <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
                            <h2>{t('session.joining')}</h2>
                            <WakeLockButton />
                        </>;
                    }
                    return (<>
                        <p className="serviceVersionNumber">App: {process.env.REACT_APP_VERSION}</p>
                        {serviceVersion !== '' &&
                            <p className="versionNumber">Service: {serviceVersion}</p>}
                        <ActivityRenderer
                            setCancelUpload={handleSetCancelUpload}
                            setCustomActivityMessage={handleCustomActivityMessageHandler}
                            activity={currentActivity}
                            {... {
                                projectName,
                                customActivityName,
                                serviceVersion,
                                sessionId: props.sessionId,
                                connectionId: connectionRef.current.connectionId,
                                activityArgs,
                                theme,
                                colorSeed: getSeed(userId),
                                callHubAsync: publicCallHubAsync,
                                getUploadUriAsync: getUploadUriAsync,
                                getDownloadUriAsync: getDownloadUriAsync,
                                userId,
                                setFooterClassName,
                                addFooterClassName

                            }} />
                    </>);
                } 
                case SessionState.SessionEnded:
                    return <>
                        <p>{t('session.ended')}</p>
                        <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
                    </>;
                case SessionState.SessionPaused:
                    return <>
                        <p>{t('session.paused')}</p>
                        <p className="serviceVersionNumber">App: {process.env.REACT_APP_VERSION}</p>
                        {serviceVersion !== '' &&
                            <p className="versionNumber">Service: {serviceVersion}</p>
                        }
                    </>;
                case SessionState.NotLogged:
                case SessionState.LoginFailed:
                default: {
                    return (<>
                        <Logo maxSize={150} />
                        <h2>{t('session.joining')}</h2>
                        <LoginForm {...{ joinSessionAsync }} />
                        {sessionState === SessionState.LoginFailed &&
                            <p>{common('error.retry')}</p>
                        }
                        <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
                    </>);
                }
            }
        } else if (connectionState === State.Reconnecting) {
            return <>
                <Spinner color="light" className='spinner' />
                <p>{t('connection.reconnecting')}</p>
                <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
            </>;
        } else if (connectionState === State.AlreadyConnected) {
            return <>
                <p>{t('connection.alreadyConnected')}</p>
                <ReconnectButton />
                <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
            </>;
        } else if (connectionState === State.AdminConnectionEnded) {
            return <>
                <p>{t('connection.adminConnectionEnded')}</p>
                <ReconnectButton />
                <p className="versionNumber">App : {process.env.REACT_APP_VERSION}</p>
            </>;
        } else if (connectionState === State.DemoConnectionEnded) {
            return <>
                <p>{t('session.demoExpired')}</p>
                <p className="versionNumber">App : {process.env.REACT_APP_VERSION}</p>
            </>;
        }
        else if (connectionState === State.TabDisconnected) {
            return <>
                <Spinner color="light" className='spinner' />
                <p>{t('connection.reconnecting')}</p>
                <p className="versionNumber">App: {process.env.REACT_APP_VERSION}</p>
            </>;
        }
    }

    return (<ThemeContext.Provider value={theme}>
            <RenderContent />
        </ThemeContext.Provider>);
}
import React from 'react';
import { ErrorDisplayer } from './ErrorDisplayer';

export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Show a reply UI
        this.setState({ hasError: true });
        console.log(info);
    }

    render() {
        if (this.state.hasError) {
            // You can show any reply UI
            return (<>
                <div className='error-container'>
                    <ErrorDisplayer message={this.props.message} />
                </div>
            </>);
        }
        return this.props.children;
    }
}
import React from 'react';
import { TranslatorProvider } from 'react-translate';

import en from '../translations/en.json';
import fr from '../translations/fr.json';

const allTranslations = [en, fr];
const locale = (navigator.language || navigator.userLanguage || 'en').substr(0, 2).toLowerCase(); // Chrome outputs 'fr-FR'

document.documentElement.setAttribute('lang', locale);

export function Translator(props) {

    const auto = !props.language || props.language === 'auto';
    const desiredLanguage = auto ? locale : props.language;
    const translations = allTranslations.find(t => t.locale === desiredLanguage) ?? en;

    return (<TranslatorProvider translations={translations}>
        {props.children}
    </TranslatorProvider>);
}
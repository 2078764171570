import React from 'react';

export const defaultTheme =
{
    baseColor: '#09C0F6',
    darkColor: '#057394',
    darkDarkColor: '#033A4A',
    lightColor: '#9DE6FB',
    mediumDarkColor: '#044D62',
    lightAccentColor: '#5CDAFF'
}

export const ThemeContext = React.createContext(defaultTheme);
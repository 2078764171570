import React from 'react';

export function SuccessIcon(props) {
    return (
        <svg
            className="fileImage"
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={props.size ?? 24}
            height={props.size ?? 24}
            style={{
                maxWidth: props.maxSize,
                maxHeight: props.maxSize,
                fill: '#ffffff',
                stroke: 'none',
                margin: '5px 0'
            }}
            viewBox="0 0 32 32">
            <circle style={{ fill: props.success ? '#4caf50' : '#d82323' }} cx="16" cy="16" r="16" />
            {props.success ?
                <path style={{ fill: '#ffffff' }} d="M 24.544381,8.4499524 14.128366,19.178468 9.0971162,13.996827 l -2.8691407,2.78711 7.8984375,8.134765 13.287109,-13.68164 z" />
                : <path style={{ fill: '#ffffff' }} d="M 10.642578 7.8144531 L 7.8144531 10.642578 L 13.171875 16 L 7.8144531 21.357422 L 10.642578 24.185547 L 16 18.828125 L 21.357422 24.185547 L 24.185547 21.357422 L 18.828125 16 L 24.185547 10.642578 L 21.357422 7.8144531 L 16 13.171875 L 10.642578 7.8144531 z" />
            }
        </svg >);
}
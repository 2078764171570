import React, { useRef } from 'react';
import { Route } from 'react-router';
import { Home } from './Home';
import { Row, Col } from 'reactstrap';
import { ErrorBoundary } from './Errors/ErrorBoundary'
import { useTranslate } from "react-translate";

export function Layout(props) {
    const footer = useRef(null);
    const t = useTranslate("Common");
    return (
        <>
            <div className="rootLayout">
                <ErrorBoundary message={t("error")}>
                    <div className="globalContainer">
                        <Row className="align-items-center" style={{ height: '100%', textAlign: 'center' }} >
                            <Col>
                                <Route path='/:sessionId?'>
                                    <Home footerRef={footer} />
                                </Route>
                            </Col>
                        </Row>
                    </div>
                </ErrorBoundary>
                <div ref={footer} className='logoContainer'>
                    <a href="https://www.compositeurdigital.com/wp/fr/accueil" target="_blank" rel="noopener noreferrer">
                        <img src="assets/logoCDUX.png" className="logoFull" alt="Compositeur Digital UX" />
                        <img src="assets/logoCDUX_small.png" className="logoSmall" alt="Compositeur Digital UX" />
                    </a>
                </div>
            </div>
        </>
    );
}
